<div class="menu-card {{ icon }}">
  <mat-card>
    <mat-card-content>
      <a *ngIf="link" [routerLink]="link">
        <img class="icon" src="/assets/img/icons/{{icon}}.svg"/>
      </a>
      <img class="icon" *ngIf="!link" src="/assets/img/icons/{{icon}}.svg"/>
    </mat-card-content>
    <ng-content></ng-content>
    <a id="{{ id }}" class="title mat-h2" [routerLink]="link" *ngIf="title && link">{{ title }}</a>
  </mat-card>
</div>
