
<form (submit)="create()" [formGroup]="form">
  <h1 mat-dialog-title="mat-dialog-title">Auszahlung</h1>
  <div mat-dialog-content="mat-dialog-content">
    <div *ngIf="apiError">
      <mat-error>Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.</mat-error>
    </div>
    <div>
      <mat-form-field>
        <mat-label>Subkonto</mat-label>
        <mat-select formControlName="subAccount">
          <mat-option *ngFor="let subAccount of subAccounts" [value]="subAccount._id">{{subAccount.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="form.hasError(&quot;required&quot;, &quot;subAccount&quot;)">Es muss ein Subkonto ausgewählt werden.</mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field><span matPrefix="matPrefix">-</span>
        <input 
          matInput="matInput" 
          type="string" 
          currencyMask 
          [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 2 }" 
          placeholder="Betrag" 
          formControlName="value"
        />
        <span matSuffix="matSuffix">€</span>
        <mat-error *ngIf="form.hasError(&quot;required&quot;, &quot;value&quot;)">Es muss ein Betrag angegeben werden.</mat-error>
        <mat-error *ngIf="form.hasError(&quot;min&quot;, &quot;value&quot;)">Der Betrag darf nicht kleiner als 0 sein.</mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <textarea matInput="matInput" placeholder="Beschreibung" formControlName="description"></textarea>
        <mat-error *ngIf="form.hasError(&quot;required&quot;, &quot;description&quot;)">Es muss eine Beschreibung angegeben werden.</mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput="matInput" placeholder="Ausführungsdatum" [matDatepicker]="picker" [min]="minDate" formControlName="date"/>
        <mat-datepicker-toggle matSuffix="matSuffix" [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker=""></mat-datepicker>
        <mat-error *ngIf="form.hasError(&quot;required&quot;, &quot;date&quot;)">Es muss ein Ausführungsdatum angegeben werden.</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions="mat-dialog-actions" align="end">
    <button mat-button="mat-button"  type="button" (click)="dialogRef.close( false )">Abbrechen</button>
    <button mat-raised-button="mat-raised-button" type="submit" color="primary">Abbuchen</button>
  </div>
</form>