
<form (submit)="submit()" [formGroup]="form">
  <h1 mat-dialog-title="mat-dialog-title">Passwort ändern</h1>
  <div mat-dialog-content="mat-dialog-content">
    <div>
      <mat-error *ngIf="apiError">Uh, da ist wohl was falsch. Bitte überprüfe dein eingegebenes Passwort!</mat-error>
    </div>
    <!-- <mat-form-field>
      <input matInput="matInput" type="password" required="required" placeholder="Altes Passwort" formControlName="old_password"/>
      <mat-error *ngIf="form.controls.old_password.invalid">Bitte gib dein aktuelles Passwort ein.</mat-error>
    </mat-form-field> -->
    <mat-form-field >
      <input matInput="matInput" type="password" name="password" autocomplete="new-password"  required="required" placeholder="Passwort" formControlName="password"  (ngModelChange)="checkPasswordRequirements()"/>
      <span class="icon-lock icon-add-s" matPrefix="matPrefix">
        <svg  height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none"/><path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"/></svg>      
      </span>
      <mat-hint *ngIf="form.controls.password.invalid">Muss mindestens 8 Zeichen enthalten</mat-hint>
      <mat-error *ngIf="form.controls.password.invalid &amp;&amp; form.controls.password.errors.required">Du musst dein neues Passwort angeben.</mat-error>
      <mat-error *ngIf="form.controls.password.invalid &amp;&amp; form.controls.password.errors.pattern">Das Passwort entspricht nicht den Anforderungen.</mat-error>
    </mat-form-field>
    <mat-form-field >
      <input matInput="matInput" type="password" name="password" autocomplete="new-password" required="required" placeholder="Passwort wiederholen" formControlName="password_repeat" [errorStateMatcher]="passwordRepeatMatcher"/>
      <span class="icon-lock icon-add-s" matPrefix="matPrefix">
        <svg  height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none"/><path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"/></svg>      
      </span>
      <mat-error *ngIf="form.invalid &amp;&amp; form.errors &amp;&amp; form.errors.notSame">Die Passwörter stimmen nicht überein.</mat-error>
    </mat-form-field>
    <mat-list>
      <mat-list-item>
        <span class="icon-clear icon-add-s" *ngIf="!passwordCheck.pwLength" mat-list-icon="mat-list-icon">
          <svg  height="24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
        </span>
        <span class="icon-check icon-add-s" *ngIf="passwordCheck.pwLength" mat-list-icon="mat-list-icon">
          <svg height="24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/></svg>        
        </span>
        <p mat-line="mat-line">Mindestens 8 Zeichen</p>
      </mat-list-item>
      <mat-list-item>
        <span class="icon-clear icon-add-s" *ngIf="!passwordCheck.capitalLetters" mat-list-icon="mat-list-icon">
          <svg  height="24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
        </span>
        <span class="icon-check icon-add-s" *ngIf="passwordCheck.capitalLetters" mat-list-icon="mat-list-icon">
          <svg height="24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/></svg>        
        </span>
        <p mat-line="mat-line">Großbuchstaben</p>
      </mat-list-item>
      <mat-list-item>
        <span class="icon-clear icon-add-s" *ngIf="!passwordCheck.lowerLetters" mat-list-icon="mat-list-icon">
          <svg  height="24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
        </span>
        <span class="icon-check icon-add-s" *ngIf="passwordCheck.lowerLetters" mat-list-icon="mat-list-icon">
          <svg height="24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/></svg>        
        </span>
        <p mat-line="mat-line">Kleinbuchstaben</p>
      </mat-list-item>
      <mat-list-item>
        <span class="icon-clear icon-add-s" *ngIf="!passwordCheck.numbers" mat-list-icon="mat-list-icon">
          <svg  height="24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
        </span>
        <span class="icon-check icon-add-s" *ngIf="passwordCheck.numbers" mat-list-icon="mat-list-icon">
          <svg height="24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/></svg>        
        </span>
        <p mat-line="mat-line">Zahlen</p>
      </mat-list-item>
      <mat-list-item>
        <span class="icon-clear icon-add-s" *ngIf="!passwordCheck.specialChars" mat-list-icon="mat-list-icon">
          <svg  height="24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
        </span>
        <span class="icon-check icon-add-s" *ngIf="passwordCheck.specialChars" mat-list-icon="mat-list-icon">
          <svg height="24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/></svg>        
        </span>
        <p mat-line="mat-line">Sonderzeichen</p>
      </mat-list-item>
    </mat-list>
  </div>
  <div mat-dialog-actions="mat-dialog-actions" align="end">
    <a *ngIf="!data.disableClose" mat-button="mat-button" (click)="dialogRef.close( false )">Abbrechen</a>
    <button mat-raised-button="mat-raised-button" color="primary" [disabled]="loading">Speichern</button>
  </div>
</form>