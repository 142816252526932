import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-card',
  templateUrl: './menu-card.component.html',
  styleUrls: ['./menu-card.component.scss']
})
export class MenuCardComponent implements OnInit {
  @Input() icon: string;
  @Input() title: string;
  @Input() link: string[];
  @Input() id: string[];

  constructor() { }

  ngOnInit() {
  }

}
