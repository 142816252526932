import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-supplier-delivery-dialog',
  templateUrl: './supplier-delivery-dialog.component.html',
  styleUrls: ['./supplier-delivery-dialog.component.scss']
})
export class SupplierDeliveryDialogComponent implements OnInit {
  public form;
  public graduationOrderValues: UntypedFormArray;
  public shipping_costs: UntypedFormArray;
  public disableShippingCost = true;

  constructor(
    public dialogRef: MatDialogRef<SupplierDeliveryDialogComponent>,
    protected formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      delivery: [0, [Validators.required]],
      delivery_free_value: [''],
      graduationOrderValue: this.formBuilder.array([
        this.formBuilder.group({
          from: [null],
          percent: [null]
        })
      ]),
      freight_exemption: [1],
      shipping_costs: this.formBuilder.array([
        this.formBuilder.group({
          shipping_costs_price: [null],
          shipping_costs_type: [null]
        })
      ]),
      apply_shipping_cost: [1]
    });

    this.graduationOrderValues = this.form.controls['graduationOrderValue'] as UntypedFormArray;
    this.shipping_costs = this.form.controls['shipping_costs'] as UntypedFormArray;

    if (this.data) {
      this.form.get('delivery').setValue(this.data.delivery);
      this.form.get('delivery_free_value').setValue(this.data.delivery_free_value);
      // this.form.get('graduationOrderValue').setValue(this.data.graduationOrderValue)
      this.form.get('freight_exemption').setValue(this.data.freight_exemption ? 0 : 1)
      this.form.get('apply_shipping_cost').setValue(this.data.apply_shipping_cost ? 0 : 1)

      this.disableShippingCost = this.form.get('freight_exemption').value === 1 ? true : false;

      for (let index = 0; index < this.data.shipping_costs.length; index++) {
        const element = this.data.shipping_costs[index];
        if (index === 0) {
          this.shipping_costs.patchValue([element]);
        } else {
          const newFormGroup = new UntypedFormGroup({
            shipping_costs_price: new UntypedFormControl(),
            shipping_costs_type: new UntypedFormControl()
          });
          this.shipping_costs.push(newFormGroup);
        }
      }

      if(this.data.shipping_costs.length != 0)
        this.form.get('shipping_costs').setValue(this.data.shipping_costs)

      // set the images loaded from the product
      for (let index = 0; index < this.data.graduationOrderValue.length; index++) {
        const element = this.data.graduationOrderValue[index];
        if (index === 0) {
          this.graduationOrderValues.patchValue([element]);
        } else {
          const newFormGroup = new UntypedFormGroup({
            from: new UntypedFormControl(),
            percent: new UntypedFormControl()
          });
          this.graduationOrderValues.push(newFormGroup);
        }
      }
      if(this.data.graduationOrderValue.length != 0)
        this.form.get('graduationOrderValue').setValue(this.data.graduationOrderValue)
    }

    // Disable Staffellung Auftragswert if Frachtkostenfreiheit is Nein.
    this.form.get('freight_exemption').value === 1 ? this.form.get('shipping_costs').disable() : this.form.get('shipping_costs').enable();
    
    this.form.get( 'freight_exemption' ).valueChanges.subscribe( val => {
      if(val === 1) {
        this.disableShippingCost = true;
        this.form.get('shipping_costs').disable()
      } else { 
        this.disableShippingCost = false;
        this.form.get('shipping_costs').enable()
      }
    })
  }

  public addGraduationOrderValue() {
    const formCtrl = new UntypedFormGroup({
      from: new UntypedFormControl(),
      percent: new UntypedFormControl()
    });
    this.graduationOrderValues.push(formCtrl);

    return false;
  }

  public removeGraduationOrderValues(i: number) {
    this.graduationOrderValues.removeAt(i);
    return false;
  }

  public addShippingCost() {
    const formCtrl = new UntypedFormGroup({
      shipping_costs_price: new UntypedFormControl(),
      shipping_costs_type: new UntypedFormControl()
    });
    this.shipping_costs.push(formCtrl);

    return false;
  }

  public removeShippingCost(i: number) {
    this.shipping_costs.removeAt(i);
    return false;
  }
}
