import { Injectable } from '@angular/core';
import { ApiService } from "../../../core/api/api.service";
import { LOCATION } from "../../types";

@Injectable()
export class LocationService {

  constructor(protected api: ApiService) {
  }

  async locationList(search?: string, indexPage: number = undefined, pageSize: number = undefined ) {
    return await this.api.rpc('crm/locations/list', { search, indexPage, pageSize });
  }

  async createLocation(location: Partial<LOCATION>, force: boolean = false) {
    return await this.api.rpc('crm/locations/create', { ...location, force });
  }

  async getLocation(id: string): Promise<LOCATION> {
    return await this.api.rpc('crm/locations/get', { _id: id });
  }

  async editLocation(location: Partial<LOCATION>, force: boolean = false) {
    return await this.api.rpc('crm/locations/edit', { ...location, force });
  }

  async removeLocation(id: string) {
    return await this.api.rpc('crm/locations/remove', { _id: id });
  }

  async getUsers(id: string) {
    return await this.api.rpc('crm/locations/getUsers', { _id: id });
  }

  async uploadLocationSupplierCSV( supplier_id: string, data: any ) {
    return await this.api.rpc('crm/locations/uploadLocationSupplierCSV', { supplier_id, data } );
  }
}
