<a mat-raised-button="mat-raised-button" (click)="goBack()">
  <span class="icon-arrow-back">
    <svg height="24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/></svg>
  </span>
  <span> <b>Lieferanten</b></span>
</a>

<div class="title">
  <h3>Warenkorb Sonderbestellung</h3>
  <h4 *ngIf="hasSpecialOrder"><b>Status:</b> Warten auf Bestätigung des Einkauf</h4>
</div>

<ng-container *ngIf="!hasSpecialOrder">

<form [formGroup]="form">
  <mat-card class="has-not-special-order">
    <div class="header">
      <h4 class="header-title">Lieferant/Onlineshop: </h4>
      <mat-form-field>
        <input matInput
               formControlName="name"
               style="font-weight: bolder;" />
        <mat-error>Lieferant/Online-Shop ist ein Pflichtfeld.</mat-error>
      </mat-form-field>
    </div>
    <div class="special-order-header">
      <div class="special-order-header-icon"></div>
      <div class="special-order-header-title"> Artikel-Nr.</div>
      <div class="special-order-header-title"> Artikelname</div>
      <div class="special-order-header-title special-order-header-title--left special-order-header-title--price"> Preis
        Brutto</div>
      <div class="special-order-header-title"> Menge</div>
      <div class="special-order-header-title special-order-header-title--left special-order-header-title--price"> Preis Gesamt</div>
      <div class="special-order-header-title"> Budget</div>
      <div class="special-order-header-title"> Link Produkt</div>
    </div>

    <div formArrayName="products"
      *ngFor="let product of productsArray.controls; let i = index">
      <div [formGroupName]="i">
        <div class="product-row-wrapper">
          <div class="product-row-wrapper-item-icon">
            <span _ngcontent-giu-c257="" mat-list-icon="mat-list-icon" class="mat-list-icon icon-local-shipping ng-star-inserted"><svg _ngcontent-giu-c257="" height="24" width="24"><path _ngcontent-giu-c257="" d="M0 0h24v24H0z" fill="none"></path><path _ngcontent-giu-c257="" d="M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zM6 18.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm13.5-9 1.96 2.5H17V9.5h2.5zm-1.5 9c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"></path></svg></span>
          </div>
          <div class="product-row-wrapper-item">
            <mat-form-field>
              <mat-label></mat-label>
              <input matInput formControlName="sku" />
              <mat-error>Artikel-Nr ist ein Pflichtfeld.</mat-error>
            </mat-form-field>
          </div>

          <div class="product-row-wrapper-item">
            <mat-form-field>
              <mat-label></mat-label>
              <input matInput formControlName="name" />
              <mat-error>Artikelname ist ein Pflichtfeld.</mat-error>
            </mat-form-field>
          </div>

          <div class="product-row-wrapper-item product-row-wrapper-item--price-brutto">
            <mat-form-field>
              <mat-label></mat-label>
              <input matInput currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 2 }"
              type="string" min="0" 
              min="0" step="1" pattern="[^\-]+" (keydown.-)="onKeydownLK($event)"
                    onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 47 && event.charCode <= 57 || event.charCode == 44 "
                    onpaste="return false"
              step="1" oninput="validity.valid||(value='');" formControlName="priceBrutto" />
              <mat-error>Preis ist ein Pflichtfeld.</mat-error>
            </mat-form-field>

            <span class="euro_sign">&euro;</span>
          </div>

          <div class="product-row-wrapper-item product-row-wrapper-item--menge">
            <button type="button" mat-stroked-button class="special-order-button-qty" [ngClass]="hasSpecialOrder && 'disabled_icon_button'" 
              (click)="onSubtractQuantity(i)">
              <span class="icon-remove">
                <svg height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 13H5v-2h14v2z"/></svg>            
              </span>
            </button>
            <mat-form-field>
              <input matInput formControlName="qty" type="number" readonly />
            </mat-form-field>
            <button type="button" mat-stroked-button class="special-order-button-qty" [ngClass]="hasSpecialOrder && 'disabled_icon_button'" 
              (click)="onAddQuantity(i)">         
              <span class="icon-add">
                <svg height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>        
              </span>
            </button>
          </div>

          <div class="product-row-wrapper-item product-row-wrapper-item--price-brutto">
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 2 }" formControlName="price" />
              <mat-error>Preis ist ein Pflichtfeld.</mat-error>
            </mat-form-field>
            <span class="euro_sign">&euro;</span>
          </div>

          <div class="product-row-wrapper-item">
            <mat-form-field>
              <mat-label></mat-label>
              <mat-select formControlName="budget">
                <mat-option *ngFor="let option of accountOptions" [value]="option._id"> {{ option.name }} </mat-option>
              </mat-select>
              <mat-error>Budget ist ein Pflichtfeld.</mat-error>
            </mat-form-field>
          </div>

          <div class="product-row-wrapper-item product-row-wrapper-item--link">
            <mat-form-field>
              <mat-label></mat-label
              ><input matInput formControlName="linkProduct" />
              <mat-error>Link Produkt ist ein Pflichtfeld.</mat-error>
            </mat-form-field>
          </div>

          <div class="product-row-wrapper-item product-row-wrapper-item--remove">
            <button type="button" class="remove_button special-order-button-qty" [ngClass]="hasSpecialOrder && 'disabled_icon_button'" mat-stroked-button (click)="onRemove(i)">
              <span class="icon-delete">
                <svg height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/></svg>                      
              </span>
            </button>
          </div>
        </div>
        <mat-form-field class="comment_artikel">
          <input matInput placeholder="Kommentar zu Artikel" formControlName="comment" />
        </mat-form-field>
      </div>
    </div>
    <button class="special-order-button-add" type="button" [ngClass]="hasSpecialOrder && 'disabled_icon_button'" (click)="onAddNewProduct()">
      <span class="icon-add">
        <svg height="24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>
      </span>
    </button>

  <div class="special-order-footer">
    <div class="special-order-footer-comment">
      <mat-form-field>
        <mat-label>Kommentar zu Bestellung</mat-label>
        <input matInput formControlName="comment" appearance="outline" />
      </mat-form-field>
    </div>

    <div class="special-order-footer-sums">
      <div class="special-order-footer-sums-item">Zwischensumme:</div>
      <div class="special-order-footer-sums-item special-order-footer-sums-item--center">{{ subTotal | currency: 'EUR' }}</div>

        <div class="special-order-footer-sums-item"><span>Lieferkosten gesamt:</span></div>
        <div class="special-order-footer-sums-item special-order-footer-sums-item--center">
        <mat-form-field class="special-order-footer-sums-item-delivery_cost">
          <input
            type="string"
            currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 2 }"
            min="0" 
            min="0" step="1" pattern="[^\-]+" (keydown.-)="onKeydownLK($event)"
            onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 47 && event.charCode <= 57 || event.charCode == 44 "
            onpaste="return false"
            step="1" oninput="validity.valid||(value='');" 
            matInput
            placeholder="{{ isDeliveryCostFilled ? '' : 'Lieferkosten eintragen' }}"
            class="special-order-footer-sums-item-delivery_cost"
            formControlName="deliveryCost"
            [(ngModel)]="isDeliveryCostFilled"
          />
        </mat-form-field>
        <span class="euro_sign">&euro;</span>
      </div>
      <div class="special-order-footer-sums-item">Gesamtsumme der Bestellung:</div>
      <div class="special-order-footer-sums-item special-order-footer-sums-item--center">
        {{ subTotal + deliveryCost | currency: 'EUR'}} 
      </div>
    </div>
  </div>

  <ngx-ui-loader></ngx-ui-loader>
  
  <div class="special-order-buttons">
    <button mat-raised-button color="default" type="button" [class.disabled-buttons]="hasSpecialOrder || disableButtons" [disabled]="hasSpecialOrder || disableButtons" (click)="onCancel()">Abbrechen</button>
    <button mat-raised-button color="primary" type="submit" [class.disabled-buttons]="productsArray.value.length === 0 || hasSpecialOrder || disableButtons" [disabled]="productsArray.value.length === 0 || hasSpecialOrder || disableButtons" (click)="onSubmit($event)">Bestellen</button>
  </div>
</mat-card>
</form>
</ng-container>

<ng-container *ngIf="hasSpecialOrder">
  <mat-card>

    <div class="header">
      <h4 class="header-title">Lieferant/Onlineshop: <b>{{ name }}</b></h4>
    </div>

  <div class="special-order">
    <div class="special-order-header">
      <div class="special-order-header-icon"></div>
      <div class="special-order-header-title"> Artikel-Nr.</div>
      <div class="special-order-header-title"> Artikelname</div>
      <div class="special-order-header-title"> Preis Brutto</div>
      <div class="special-order-header-title"> Menge</div>
      <div class="special-order-header-title"> Preis Gesamt</div>
      <div class="special-order-header-title"> Budget</div>
      <div class="special-order-header-title special-order-header-title--link_product"> Link Produkt</div>
    </div>
      <div *ngFor="let productITEM of products | groupBy: 'budget'">
        <div class="special-order-budget">Budget: <b>{{ accounts[productITEM.items[0].budget][0].name }}</b></div>
        <div class="product-row" *ngFor="let product of productITEM.items; let i = index">
          <div class="product-row-wrapper">
            <div class="product-row-wrapper-item-icon">
              <span _ngcontent-giu-c257="" mat-list-icon="mat-list-icon" class="mat-list-icon icon-local-shipping ng-star-inserted"><svg _ngcontent-giu-c257="" height="24" width="24"><path _ngcontent-giu-c257="" d="M0 0h24v24H0z" fill="none"></path><path _ngcontent-giu-c257="" d="M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zM6 18.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm13.5-9 1.96 2.5H17V9.5h2.5zm-1.5 9c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"></path></svg></span>
            </div>
            <div class="product-row-wrapper-item">{{product.sku}}</div>
            <div class="product-row-wrapper-item">{{ product.name }}</div>
            <div class="product-row-wrapper-item">{{ product.priceBrutto | currency: 'EUR'}}</div>
            <div class="product-row-wrapper-item">{{ product.qty }}</div>
            <div class="product-row-wrapper-item">{{ product.qty * product.priceBrutto | currency: 'EUR' }}</div>
            <div class="product-row-wrapper-item">{{ accounts[product.budget][0].name }}</div>
            <div class="product-row-wrapper-item--link_product">{{ product.linkProduct }}</div>
          </div>
          <div class="product-row-wrapper-item-comment" *ngIf="product.comment">
            Kommentar: <b>{{ product.comment }}</b>
          </div>
        </div>
        <h3 class="shoppingcart-sub-price budget-sub-price">
          Zwischensumme: <span>{{ productITEM.subBalance | currency: 'EUR' }}</span>
        </h3>
      </div>

      <div class="prices">
        <div>
          <p *ngIf="comment">Kommentar: <b>{{comment}}</b></p>
        </div>

        <div>
          <div>
            <h3 class="shoppingcart-sub-price">
              Lieferkosten gesamt:
              <span>{{ deliveryCost | currency: 'EUR'}}</span>
            </h3>
          </div>
          <h3
            class="shoppingcart-sub-price"
          >
            Gesamtsumme der Bestellung: 
            <span>
              <b>{{ (subTotal + deliveryCost) | currency: 'EUR' }}</b>
            </span>
          </h3>
        </div>
      </div>
  </div>
</mat-card>
</ng-container>
