import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/api/api.service';
import { User } from '../../types';
import { Subject } from 'rxjs';


type loginResult = { token: string };

@Injectable()
export class UserService {

  public activeUser: string;
  // userIdAndGroupSubject = new Subject<any>();
  // userIdAndGroupObserver = this.userIdAndGroupSubject.asObservable();

  constructor(protected api: ApiService) {
  }

  async createTestuser() {
    const shortToken = await this.api.rpc('crm/mickey/login', { id: 'a', name: 'b' });
    this.api.authenticate(shortToken.token);
    await this.createUser({ identifier: 'test@bonnie.cloud', password: 'test' });
  }

  async login(mail: string, password: string): Promise<string> {
    const { token }: loginResult = await this.api.rpc(
      'crm/auth/login',
      { identifier: mail, password },
    );

    return token;
  }

  async userList(search: string = null) {
    let data: { identifier?: string } = {};
    if (search) {
      data.identifier = search;
    }
    return await this.api.rpc('crm/user/list', data);
  }

  async createUser(user: Partial<User>) {
    return await this.api.rpc('crm/user/create', user);
  }

  async getUser(id: string): Promise<User> {
    return await this.api.rpc('crm/user/get', { _id: id });
  }


  async editUser(user: Partial<User>) {
    return await this.api.rpc('crm/user/edit', user);
  }

  async removeUser(_id: string) {
    return await this.api.rpc('crm/user/remove', { _id });
  }

  async requestReset(identifier: string) {
    return await this.api.rpc('crm/auth/requestPasswortReset', { identifier });
  }

  async passwordReset(id: string, resetToken: string, password: string) {
    return await this.api.rpc('crm/auth/resetPassword', { id, resetToken, password });
  }

  async sendMail(data: { subject: string, body: string, trx: { rcpt: string[], from: string[] } }) {
    return await this.api.rpc('crm/email/send', data);
  }

  async changePassword(id: string, oldPassword: string, password: string) {
    return await this.api.rpc('crm/auth/changePassword', { id, oldPassword, password });
  }
  //DP
  async changeUserPassword(id: string, password: string) {
    return await this.api.rpc('crm/auth-admin/changePassword', { id, password });
  }

  async addLocation(userId: string, locationId: string) {
    return await this.api.rpc('crm/user/addLocation', { user_id: userId, location_id: locationId });
  }

  async removeLocation(userId: string, locationId: string) {
    return await this.api.rpc('crm/user/removeLocation', { user_id: userId, location_id: locationId });
  }

  // async refreshUserIdentifierAndGroup(user_identifier: string, user_group: string) {
  //   let user = { user_identifier: user_identifier, user_group: user_group };

  //   this.userIdAndGroupSubject.next(user);
  // }

  genPass(): string {
    let length = 8,
      charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!?#+=',
      retVal = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  async azureLogin(azureToken: string): Promise<string> {
    const { token } = await this.api.rpc(
      'crm/azure-auth/login',
      { azureToken },
    );

    return token;
  }

}
