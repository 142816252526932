
<h1 mat-dialog-title="mat-dialog-title">Datenschutzerklärung</h1>
<div mat-dialog-content="mat-dialog-content">
  <p>We have so many things that we have to do better... and certainly ipsum is one of them. My text is long and beautiful, as, it has been well documented, are various other parts of my website.</p>
  <p>Lorem Ipsum is the single greatest threat. We are not - we are not keeping up with other websites. I was going to say something extremely rough to Lorem Ipsum, to its family, and I said to myself, "I can't do it. I just can't do it. It's inappropriate. It's not nice." An 'extremely credible source' has called my office and told me that Lorem Ipsum's birth certificate is a fraud. If Trump Ipsum weren’t my own words, perhaps I’d be dating it. All of the words in Lorem Ipsum have flirted with me - consciously or unconsciously. That's to be expected.</p>
  <p>You're telling the enemy exactly what you're going to do. No wonder you've been fighting Lorem Ipsum your entire adult life. We have so many things that we have to do better... and certainly ipsum is one of them. Lorem Ipsum is FAKE TEXT!</p>
  <p>My text is long and beautiful, as, it has been well documented, are various other parts of my website. You could see there was text coming out of her eyes, text coming out of her wherever.</p>
  <p>If Trump Ipsum weren’t my own words, perhaps I’d be dating it. He’s not a word hero. He’s a word hero because he was captured. I like text that wasn’t captured. I was going to say something extremely rough to Lorem Ipsum, to its family, and I said to myself, "I can't do it. I just can't do it. It's inappropriate. It's not nice." That other text? Sadly, it’s no longer a 10. You're telling the enemy exactly what you're going to do. No wonder you've been fighting Lorem Ipsum your entire adult life. Lorem Ipsum is unattractive, both inside and out. I fully understand why it’s former users left it for something else. They made a good decision.</p>
  <p>I write the best placeholder text, and I'm the biggest developer on the web by far... While that's mock-ups and this is politics, are they really so different?</p>
  <p>I think my strongest asset maybe by far is my temperament. I have a placeholding temperament. I think my strongest asset maybe by far is my temperament. I have a placeholding temperament. We have so many things that we have to do better... and certainly ipsum is one of them. Lorem Ipsum is FAKE TEXT!</p>
  <p>I write the best placeholder text, and I'm the biggest developer on the web by far... While that's mock-ups and this is politics, are they really so different? The best taco bowls are made in Trump Tower Grill. I love Hispanics! I write the best placeholder text, and I'm the biggest developer on the web by far... While that's mock-ups and this is politics, are they really so different? Despite the constant negative ipsum covfefe. Be careful, or I will spill the beans on your placeholder text.</p>
  <p>Look at that text! Would anyone use that? Can you imagine that, the text of your next webpage?! We have so many things that we have to do better... and certainly ipsum is one of them. I think the only card she has is the Lorem card.</p>
  <p>If Trump Ipsum weren’t my own words, perhaps I’d be dating it. When other websites give you text, they’re not sending the best. They’re not sending you, they’re sending words that have lots of problems and they’re bringing those problems with us. They’re bringing mistakes. They’re bringing misspellings. They’re typists… And some, I assume, are good words.</p>
  <p>My text is long and beautiful, as, it has been well documented, are various other parts of my website. If Trump Ipsum weren’t my own words, perhaps I’d be dating it.</p>
</div>
<div mat-dialog-actions="mat-dialog-actions" align="end"><a mat-button="mat-button" (click)="decline()">Ablehnen</a><a mat-raised-button="mat-raised-button" color="primary" (click)="accept()">Akzeptieren</a></div>