import { Injectable } from '@angular/core';
import { ApiService } from "../../../core/api/api.service";

@Injectable( {
  providedIn: 'root',
} )
export class TagService {

  constructor(protected api: ApiService) { }

  async list() {
    return await this.api.rpc('crm/so-tag/list', {});
  }

  async create( tag ) {
    return await this.api.rpc('crm/so-tag/create', tag);
  }

  async get( id: string ) {
    return await this.api.rpc('crm/so-tag/get', {_id: id});
  }

  async update( tag ) {
    return await this.api.rpc('crm/so-tag/edit', tag);
  }

  async delete( id: string ) {
    return await this.api.rpc('crm/so-tag/remove', {_id: id});
  }

}
