import { Action } from '@ngrx/store';
import { UserGroup } from './authentication.reducer';

export const SET_AUTHENTICATED = '[Auth] Set Authenticated';
export const SET_UNAUTHENTICATED = '[Auth] Set Unauthenticated';
export const SET_USERGROUP = '[Auth] Set UserGroup';

export class SetAuthenticated implements Action {
  readonly type = SET_AUTHENTICATED;

}

export class SetUnauthenticated implements Action {
  readonly type = SET_UNAUTHENTICATED;

}

export class SetUserGroup implements Action {
  readonly type = SET_USERGROUP;

  constructor(public payload: UserGroup | string) {}
}

export type AuthActions = SetAuthenticated
                          | SetUnauthenticated
                          | SetUserGroup;

