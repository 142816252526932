
<div class="container-wrap">
  <h1 mat-dialog-title="mat-dialog-title">{{ data.title }}</h1>
  <div mat-dialog-content="mat-dialog-content">
    <p [innerHTML]="data.message"></p>
  </div>
  <div mat-dialog-actions="mat-dialog-actions" align="end">
    <button mat-button="mat-button" *ngIf="data.buttons?.abort"  type="button" (click)="dialogRef.close( false )">{{ data.buttons?.abort || 'Abbrechen' }}</button>
    <button mat-raised-button="mat-raised-button" color="primary" (click)="data.callback ? data.callback() : dialogRef.close(true)">{{ data.buttons?.submit || 'OK' }}</button>
  </div>
</div>