import { PipeTransform, Pipe } from '@angular/core';
import groupBy from 'lodash/groupBy';

@Pipe({
  name: 'groupBy'
})
export class SpecialOrderPipe implements PipeTransform {
  transform(value: any, type:any) {
    let result: any = [];

    let data = groupBy(value, n => n.budget)
    for (let item in data) {
      let dataToAdd = {
        ["items"]: data[item],
        "subBalance": 0
      };

      for(let product in dataToAdd.items) {
        dataToAdd.subBalance += dataToAdd.items[product].qty * dataToAdd.items[product].priceBrutto;
      }
      
      result.push(dataToAdd);
    }

    return result;
  }
}
