
import { NotificationService } from './../../services/notification/notification.service';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import * as fromRoot from '../../../app.reducer';
import { CreateComponent } from './create/create.component';
import { UpdateComponent } from './update/update.component';
import { StandingOrderService } from '../../services/standing-order/standing-order.service';
import { ConfirmComponent } from '../confirm/confirm.component';
import { LocationService } from '../../services/location/location.service';
import { AccountService } from '../../services/account/account.service';
import { TagService } from '../../services/tag/tag.service';
import { UserGroup } from '../../../authentication/authentication.reducer';

@Component( {
  selector: 'app-standing-order',
  templateUrl: './standing-order.component.html',
  styleUrls: [ './standing-order.component.scss' ],
} )

export class StandingOrderComponent implements OnInit {
  @Input() location: string;
  public currentLocation: any;
  standingOrders: any[];

  nextDate: Date;
  showDate: Date;
  standingOrdersTotal: number;

  locations: { [ id: string ]: string } = {};
  accounts: { [ id: string ]: string } = {};
  tags: { [ id: string ]: string } = {};
  tagsStatus: { [ id: string ]: boolean } = {};
  public user$: Observable<UserGroup | string>;
  public userGroup = UserGroup;

  constructor(
    private standingOrderService: StandingOrderService,
    private locationService: LocationService,
    private accountService: AccountService,
    private tagsService: TagService,
    private notification: NotificationService,
    private dialog: MatDialog,
    private store: Store<fromRoot.State>
  ) {
    this.user$ = this.store.select(fromRoot.getUserGroup);
  }

  async ngOnInit(): Promise<void> {
    this.getNextDate();

    let [ locations, accounts, tags ] = await Promise.all( [
      this.locationService.locationList(),
      this.accountService.list(),
      this.tagsService.list(),
      this.loadStandingOrders(),
    ] );

    if(this.location) {
      this.locationService.getLocation(this.location).then( location => {
        this.currentLocation  = location;
      } );
    }

    for ( let account of accounts.accounts ) {
      this.accounts[ account._id ] = account.name;
    }

    for ( let location of locations.locations ) {
      this.locations[ location._id ] = location.name;
    }

    for ( let tag of tags.tags ) {
      this.tags[ tag._id ] = tag.name;
      this.tagsStatus[ tag._id ] = tag.status;
    }
  }

  async createStandingOrder() {
    const dialogRef = await this.dialog.open( CreateComponent, {
      width: '400px',
      data: {
        location: this.location,
      },
    } );

    dialogRef.afterClosed().subscribe( async result => {
      if ( result ) {
        await this.loadStandingOrders();
        this.notification.showSnackbar( 'Der Dauerauftrag wurde erfolgreich angelegt.', 'OK' );
      }
    } );
  }

  async editStandingOrder( id: string ) {
    const dialogRef = await this.dialog.open( UpdateComponent, {
      width: '400px',
      data: {
        id,
        location: this.location,
      },
    } );

    dialogRef.afterClosed().subscribe( async result => {
      if ( result ) {
        await this.loadStandingOrders();
        this.notification.showSnackbar( 'Der Dauerauftrag wurde erfolgreich bearbeitet.', 'OK' );
      }
    } );
  }

  async deleteStandingOrder( id: string ) {
    const dialogRef = await this.dialog.open( ConfirmComponent, {
      data: {
        title: 'Dauerauftrag löschen',
        message: 'Soll dieser Dauerauftrag wirklich unwiderruflich gelöscht werden?',
        buttons: {
          submit: 'Löschen',
          abort: 'Abbrechen',
        },
      },
    } );

    dialogRef.afterClosed().subscribe( async result => {
      if ( result ) {
        await this.standingOrderService.delete( id );
        await this.loadStandingOrders();
        this.notification.showSnackbar( 'Der Dauerauftrag wurde erfolgreich gelöscht.', 'OK' );
      }
    } );
  }

  async loadStandingOrders() {
    try {
      let orders = await this.standingOrderService.list( this.location );
      this.standingOrders = orders.orders;
      this.getStandingOrdersTotal();
    } catch ( e ) {
      this.notification.showSnackbar( 'Es ist ein Fehler aufgetreten. Versuche es bitte später noch einmal.' );
    }
  }

  protected getNextDate() {
    let nextDate = new Date();
    nextDate.setDate(nextDate.getDate() + 31);
    // if ( nextDate.getDate() === 1 ) {
    //  this.nextDate = nextDate;
    //  return;
    //}

    // nextDate.setMonth( nextDate.getMonth() );
    this.nextDate = nextDate;
    var m =nextDate.getMonth() ;  
    var y = nextDate.getFullYear(); 
    this.showDate= new Date(y, m, 1);
  }

  protected getStandingOrdersTotal() {
    this.standingOrdersTotal = 0;
    let date = new Date();
    date.setDate(date.getDate() + 30);

    let dateMonth = date.getMonth();
    let dateYear = date.getFullYear();
    
    let increaseMonth = date.getMonth() === 11 ? 0 : date.getMonth() + 1;
    let increaseYear = date.getMonth() === 11 ? date.getFullYear() + 1 : date.getFullYear();

    for ( let standingOrder of this.standingOrders ) {
      let multiOrder = 1;
      let getOrder = false;

      let startDateDay = new Date(standingOrder.startDate).getDate()
      let startDateMonth = new Date(standingOrder.startDate).getMonth()
      let startDateYear = new Date(standingOrder.startDate).getFullYear()

      if(startDateYear != dateYear){
        getOrder = true; 
      }

      if( new Date(standingOrder.startDate).getTime() === new Date(standingOrder.endDate).getTime()) {
        multiOrder = 1;
      } else if( new Date(standingOrder.startDate).getTime() < new Date().getTime() && new Date(standingOrder.endDate).getTime() > date.getTime()) {
        multiOrder = 1;
      }
      if(
          (new Date(standingOrder.startDate).getTime() == new Date(standingOrder.endDate).getTime() 
          && ((startDateMonth === dateMonth || startDateMonth === increaseMonth ) && startDateDay <= date.getDate())
          && startDateYear === dateYear) || 
          (new Date(standingOrder.startDate).getTime() < date.getTime()) && new Date(standingOrder.endDate).getTime() > new Date().getTime()) {
        this.standingOrdersTotal += standingOrder.value * standingOrder.amount * multiOrder;
      } 
    }
  }
}
