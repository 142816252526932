
<div class="login-wrap">
  <div class="container__row">
    <div class="col-4 col-sm-4 col-md-offset-2 col-md-4 col-lg-offset-4 col-lg-4">
      <form class="full-width-form" (submit)="requestPassword()">
        <mat-card class="centered-title">
          <mat-card-header>
            <mat-card-title>Passwort vergessen</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div>
              <mat-form-field>
                <input matInput="matInput" type="email" required="required" placeholder="E-Mail-Adresse" [formControl]="mailField"/>
                <mat-error *ngIf="mailField.hasError(&quot;required&quot;)">Es muss eine E-Mail-Adresse angegeben werden.</mat-error>
                <mat-error *ngIf="mailField.hasError(&quot;email&quot;)">Es muss eine gültige E-Mail-Adresse angegeben werden.</mat-error>
              </mat-form-field>
            </div>
          </mat-card-content>
          <mat-card-actions align="end"><a mat-button="mat-button" [routerLink]="[&quot;/login&quot;]">Zurück</a>
            <button mat-raised-button="mat-raised-button" color="primary">Passwort anfordern</button>
          </mat-card-actions>
        </mat-card>
      </form>
    </div>
  </div>
</div>