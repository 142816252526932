import { NgModule } from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ImpressumComponent } from './impressum.component';

@NgModule({
  declarations: [ ImpressumComponent ],
  imports: [
    CommonModule,
    NgxUiLoaderModule,
    SharedModule,
    MatPaginatorModule
  ]
})
export class ImpressumModule { }
