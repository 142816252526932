import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';


import { EnvironmentService } from '../../environment.service';
import { AuthService } from '../auth/auth.service';
import { StorageService } from '../storage/storage.service';

@Injectable()
export class ApiService {

  public counter = 0;
  public requests: {
    [index: number]: [Function, Function]
  } = {};
  public ws: WebSocket;
  public initialTimeout = Math.round(Math.random() * 500 + 500);
  public reconnectTimeout = this.initialTimeout;
  public pingTimeout = 45000;
  public pingTimeoutRef: number;
  public sentAuth: boolean;

  private SERVER:any;

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private storageService: StorageService
  ) {
    this.SERVER = (new EnvironmentService()).SERVER;
    this.auth
      .isAuthenticated()
      .then(() => this.connect(), () => this.connect());
  }

  connect() {   
    this.ws = new WebSocket(`${this.SERVER.ws}${this.SERVER.host}/ws`);

    this.ws.onopen = open => {

      // console.log('socket:open', open);
      this.sentAuth = false;
      this.reconnectTimeout = this.initialTimeout;
      this.resetPing();

    };

    this.ws.onerror = error => console.log('socket:error', error);
    this.ws.onmessage = ev => this.receive(ev);

    window.onbeforeunload = () => this.ws.close();

    this.ws.onclose = close => {

      console.log('socket:close', close);

      setTimeout(
        () => this.connect(),
        this.reconnectTimeout
      );

      if (this.reconnectTimeout < 1000 * 60 * 2) {
        this.reconnectTimeout *= 2;
      }
    };
  }

  rpc(action: string, data: any): Promise<any> {
    let request: ClientRequest = {
      t: 0,
      i: ++this.counter,
      s: action.substring(0, action.indexOf('/')),
      a: action.substring(action.indexOf('/'))
    };

    if (data) {
      request.d = data;
    }

    try {
      return new Promise((resolve, reject) => {
        this.requests[request.i] = [resolve, reject];
        this
          .whenConnected(() => { 
            this.storageService.remove('expireDate');
            const date = new Date();
            this.storageService.set('expireDate', date.getTime() + 30*60000);
            this.ws.send(JSON.stringify(request)) 
          })
          .catch(reject);
        // setTimeout race?
      });
    } catch (e) {
      console.error('could not send via ws');
    }
  }

  receive(ev: MessageEvent) {
    try {
      let message: ClientResponse = JSON.parse(ev.data);
      if (ClientType.rpc === message.t) {
        if (200 !== message.s) {
          this.requests[message.i][1](message.d);
          if (401 === message.s) {
            // this.auth.logout();
            window.location.href = '/dashboard';
          }
          if(404 === message.s && message.d.msg == `User with given ID or Identifier doesn't exist`) {
            this.auth.logout();
            window.location.href = '/login';
          }
        } else {
          this.requests[message.i][0](message.d);
        }
        delete this.requests[message.i];
      }
    } catch (e) {
      console.error('receiving', e, ev);
    }
  }

  whenConnected(callback: Function): Promise<any> {
    return new Promise((resolve, reject) => {

      if (this.ws) {

        if (WebSocket.OPEN === this.ws.readyState) {

          if (this.auth.authenticated && !this.sentAuth) {
            this.authenticate(this.auth.token);
            this.sentAuth = true;
          }

          callback();
          this.resetPing();
          return resolve('');
        }
      } else {
        this.connect();
      }

      setTimeout(() => this.whenConnected(callback), 750);
    });
  }


  async httpRPC(rpc: string, payload: any = {}): Promise<any> {

    await this.auth.isAuthenticated();

    let headers = null;
    if (this.auth.authenticated) {
      headers = new HttpHeaders({ 'x-session': this.auth.token });
    }

    let promise = this.http
      .post(`${this.SERVER.protocol}${this.SERVER.host}/api/${rpc}`, payload, { headers })
      .toPromise();

    promise.catch((error: HttpErrorResponse) => {
      if (401 === error.status) {
        this.auth.logout();
      }
    });

    return promise;
  }

  resetPing() {
    clearTimeout(this.pingTimeoutRef);
    this.pingTimeoutRef = window.setTimeout(() => this.ping(), this.pingTimeout);
  }

  ping() {
    if (!this.ws || (WebSocket.OPEN !== this.ws.readyState)) {
      console.log('wanted to ping but no ws');
      return;
    }
    this.ws.send('0');
    this.resetPing();
  }

  authenticate(session: string) {
    let request: ClientRequest = {
      t: ClientType.auth,
      d: session
    };
    this.ws.send(JSON.stringify(request));
  }
}


export interface ClientRequest {
  /**
   * identifier
   */
  i?: number,
  /**
   * type
   */
  t: ClientType,
  /**
   * service
   */
  s?: string,
  /**
   * action
   */
  a?: string,
  /**
   * data
   */
  d?: any
}

export enum ClientType {
  rpc = 0,
  auth = 1,
  broadcast = 2
}

export interface ClientResponse {
  /**
   * identifier
   */
  i?: number,
  /**
   * type
   */
  t: ClientType,
  /**
   * data
   */
  d?: any,
  /**
   * statusCode
   */
  s?: number
}
