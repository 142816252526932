<div class="container__row">
  <div class="col-4 col-sm-4 col-md-4 col-lg-4">
    <app-menu-card *ngIf="(user$ | async) !== userGroup.lieferant" icon="shop" title="Einkauf" id="einkauf"
      [link]="[&quot;/einkauf&quot;]">
      <!-- <mat-card-actions></mat-card-actions> -->
    </app-menu-card>
  </div>
  <div *ngIf="(user$ | async) !== userGroup.lieferant " class="col-4 col-sm-4 col-md-4 col-lg-4">
    <app-menu-card icon="standort" title="{{((user$ | async) !== userGroup.user) ? 'Standorte' : 'Standort'}}" id="location"
      [link]="[&quot;/locations&quot;]">
      <!-- <mat-card-actions></mat-card-actions> -->
    </app-menu-card>
  </div>
  <div *ngIf="(user$ | async) !== userGroup.lieferant" class="col-4 col-sm-4 col-md-4 col-lg-4">
    <app-menu-card icon="calculator" title="{{((user$ | async) !== userGroup.user) ? 'Budget' : 'Budget' }}" id="account"
      [link]="[&quot;/accounts&quot;]">
      <!-- <mat-card-actions></mat-card-actions> -->
    </app-menu-card>
  </div>
  <!-- <div *ngIf="(user$ | async) !== userGroup.einkauf && (user$ | async) === userGroup.user" class="col-1 col-sm-2 col-md-2 col-lg-2"></div> -->

  <div *ngIf="(user$ | async) !== userGroup.lieferant && (user$ | async) !== userGroup.admin_einkauf && (user$ | async) !== userGroup.admin && (user$ | async) !== userGroup.super_admin && (user$ | async) !== userGroup.einkauf" class="d-none d-md-block col-lg-2"></div>
  <div
    *ngIf="(user$ | async) !== userGroup.user && (user$ | async) !== userGroup.lieferant"
    class="col-4 col-sm-4 col-md-4 col-lg-4">
    <app-menu-card icon="user" title="Benutzer" [link]="[&quot;/settings&quot;, &quot;users&quot;]" id="user"> 
      <!-- <mat-card-actions>
        <a mat-button="mat-button" (click)="changePassword()">Passwort ändern</a>
      </mat-card-actions> -->
    </app-menu-card>
  </div>
  <div
    *ngIf="(user$ | async) !== userGroup.lieferant && (user$ | async) !== userGroup.admin && (user$ | async) !== userGroup.admin_einkauf && (user$ | async) !== userGroup.super_admin && (user$ | async) !== userGroup.einkauf"
    class="col-4 col-sm-4 col-md-4 col-lg-4">
    <div class="menu-card user">
      <mat-card>
        <mat-card-content>
          <a id="dashboard" *ngIf="[&quot;/dashboard&quot;]" [routerLink]="[&quot;/dashboard&quot;]" (click)="changePassword()">
            <img class="icon" src="/assets/img/icons/user.svg" />
          </a>
        </mat-card-content>
        <!-- <mat-card-actions>
          <a mat-button="mat-button" (click)="changePassword()">Passwort ändern</a>
        </mat-card-actions>  -->
        <a id="change_password" class="title mat-h2" (click)="changePassword()">Passwort ändern</a>
      </mat-card>
    </div>
  </div>

  <div
    *ngIf="(user$ | async) !== userGroup.user && (user$ | async) !== userGroup.lieferant"
    class="col-4 col-sm-4 col-md-4 col-lg-4">
    <app-menu-card id="settings" icon="settings" title="Einstellungen" [link]="[&quot;/settings&quot;]">
      <!-- <mat-card-actions></mat-card-actions> -->
    </app-menu-card>
  </div>
  <!-- <div *ngIf="(user$ | async) !== userGroup.user && (user$ | async) !== userGroup.lieferant"
    class="col-4 col-sm-4 col-md-4 col-lg-4">
    <app-menu-card icon="basketWT" title="Warenkorb" [link]="[&quot;/basket&quot;]">
      <mat-card-actions></mat-card-actions>
    </app-menu-card>
  </div> -->
  <div
    *ngIf="(user$ | async) == userGroup.user"
    class="col-4 col-sm-4 col-md-4 col-lg-4">
    <app-menu-card id="my-basket" icon="bestellung_v01" title="Meine Bestellungen" [link]="[&quot;/userbasket&quot;]">
      <!-- <mat-card-actions></mat-card-actions> -->
    </app-menu-card>
  </div>

  <div *ngIf="(user$ | async) == userGroup.einkauf || (user$ | async) == userGroup.admin || (user$ | async) == userGroup.admin_einkauf || (user$ | async) == userGroup.super_admin " class="col-4 col-sm-4 col-md-4 col-lg-4">
    <app-menu-card id="einkauforders" icon="bestellung_v01" title="Bestellübersicht/Freigabe" [link]="[&quot;/einkauforders&quot;]"></app-menu-card>
  </div>
  
  <!-- <div *ngIf="(user$ | async) == userGroup.einkauf" class="col-4 col-sm-4 col-md-4 col-lg-4 ">
    <app-menu-card id="freigaben" icon="freigabeBWT" title="Freigaben" [link]="[&quot;/einkaufbasket&quot;]"></app-menu-card>
  </div> -->

  <div *ngIf="(user$ | async) == userGroup.einkauf" class="col-2 col-sm-4 col-md-4 col-lg-4"></div>

  <!-- <div *ngIf="(user$ | async) == userGroup.einkauf || (user$ | async) == userGroup.admin || (user$ | async) == userGroup.super_admin " class="col-4 col-sm-4 col-md-4 col-lg-4">
    <app-menu-card id="einkauforders" icon="bestellung_v01" title="Bestellübersicht/Freigabe" [link]="[&quot;/einkauforders&quot;]"></app-menu-card>
  </div> -->
</div>