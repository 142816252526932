import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/api/api.service';

@Injectable( {
  providedIn: 'root',
} )
export class StandingOrderService {

  constructor( protected api: ApiService ) {
  }

  protected async _call( path: string, data: any = {} ) {
    return await this.api.rpc( `crm/${ path }`, data );
  }

  async list( location: string = null, subAccount: string = null ) {
    let data: { location?: string, account?: string } = {};
    if ( location ) {
      data.location = location;
    }
    if ( subAccount ) {
      data.account = subAccount;
    }

    return await this._call( 'order/list', data );
  }

  async create( standingOrder ) {
    return await this._call( 'order/create', standingOrder );
  }

  async get( id: string ) {
    return await this._call( 'order/get', { _id: id } );
  }

  async update( standingOrder ) {
    return await this._call( 'order/edit', standingOrder );
  }

  async delete( id: string ) {
    return await this._call( 'order/remove', { _id: id } );
  }
}
