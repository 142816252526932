import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationComponent } from './authentication.component';
import { LoginComponent } from './login/login.component';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { SharedModule } from '../shared/shared.module';
import { ResetComponent } from './reset/reset.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

@NgModule( {
  declarations: [
    AuthenticationComponent,
    LoginComponent,
    ResetComponent,
    ResetPasswordComponent
  ],
  imports: [
    AuthenticationRoutingModule,
    CommonModule,
    SharedModule
  ]
} )
export class AuthenticationModule {
}
