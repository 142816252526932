import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ImpressumComponent } from './impressum/impressum.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { AGBComponent } from './agb/agb.component';
import { RouteGuard } from './route.guard';

const routes: Routes = [
  {
    path: 'locations',
    canActivate: [ RouteGuard ],
    loadChildren: () => import('./locations/locations.module').then(m => m.LocationsModule),
  }, 
  {
    path: 'supplier',
    canActivate: [ RouteGuard ],
    loadChildren: () => import('./supplierBasket/supplierBasket.module').then(m => m.SupplierBasketModule),
  },
  {
    path: 'userbasket',
    canActivate: [ RouteGuard ],
    loadChildren: () => import('./basketuser/basketuser.module').then(m => m.UserBasketModule),
  },
  {
    path: 'einkaufbasket',
    canActivate: [ RouteGuard ],
    loadChildren: () => import('./einkaufbasket/einkaufbasket.module').then(m => m.EinkaufBasketModule),
  },
  {
    path: 'einkauforders',
    canActivate: [ RouteGuard ],
    loadChildren: () => import('./ordersEinkauf/ordersEinkauf.module').then(m => m.EinkaufOrdersModule),
  },
  // {
  //   path: 'supplier-basket-list',
  //   canActivate: [ RouteGuard ],
  //   loadChildren: './basketuser/basketuser.module#UserBasketModule',
  // },
  {
    path: 'accounts',
    canActivate: [ RouteGuard ],
    loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsModule),
  },
  {
    path: 'settings',
    canActivate: [ RouteGuard ],
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
  },
  {
    path: 'einkauf',
    canActivate: [ RouteGuard ],
    loadChildren: () => import('./einkauf/shops.module').then(m => m.ShopsModule),
  },
  {
    path: 'basket',
    canActivate: [ RouteGuard ],
    loadChildren: () => import('./basket/basket.module').then(m => m.BasketModule),
  },
  {
    path: 'impressum',
    canActivate: [ RouteGuard ],
    component: ImpressumComponent,
  },
  {
    path: 'datenschutz', 
    // canActivate: [ RouteGuard ],
    component: DatenschutzComponent,
  },
  {
    path: 'agb', 
    // canActivate: [ RouteGuard ],
    component: AGBComponent,
  },

  { path: '', redirectTo: '/login', pathMatch: 'full' },
];

@NgModule( {
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ],
} )
export class AppRoutingModule {
}
