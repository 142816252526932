import { environment } from '../environments/environment';
export const SERVERS = {
  DEV: {
    host: 'localhost:8000',
    protocol: 'https://',
    ws: 'wss://',
    name: 'dev',
    email: 'einkaufsplattform@businessbynature.de'
  },
  TESTWT: {
    host: 'kiku-test.wiso-tech-services.de',
    protocol: 'https://',
    ws: 'wss://',
    name: 'testwt',
    email: 'dockertest@wiso-tech.de'
  },
  KIKUTEST: {
    host: 'kiku-test.wiso-tech-services.de',
    protocol: 'https://',
    ws: 'wss://',
    name: "kikutest",
    email: 'dockertest@wiso-tech.de'
  },
  KIKUAUTOTEST: {
    host: 'kiku-autotest.wiso-tech-services.de',
    protocol: 'https://',
    ws: 'wss://',
    name: "kikuautotest",
    email: 'kita-autotest@wiso-tech.de'
  },
  KIKU: {
    host: 'kiku.wiso-tech-services.de',
    protocol: 'https://',
    ws: 'wss://',
    name: "kiku",
    email: 'demo@mindsetsolution.de'
  },
  LIVE: {
    host: 'kiku.de',
    protocol: 'https://',
    ws: 'wss://',
    name: 'live',
    email: 'einkaufsplattform@businessbynature.de'
  },
  KIKU3: {
    host: 'kiku3.wiso-tech-services.de',
    protocol: 'https://',
    ws: 'wss://',
    name: 'kiku3',
    email: 'einkaufsplattform@businessbynature.de'
  },
  EINKAUF: {
    host: 'kiku.mindsetsolution.de',
    protocol: 'https://',
    ws: 'wss://',
    name: 'einkauf',
    email: 'kiku@mindsetsolution.de'
  },
  EINKAUF2: {
    host: 'einkauf2.wiso-tech-services.de',
    protocol: 'https://',
    ws: 'wss://',
    name: 'einkauf2',
    email: 'demo@mindsetsolution.de'
  },
  EINKAUF3: {
    host: 'test.mindsetsolution.de', // this can be also einkauf3.wiso-tech-services.de
    protocol: 'https://',
    ws: 'wss://',
    name: 'einkauf3',
    email: 'demo@mindsetsolution.de',
    displayHost: 'test.mindsetsolution.de',
  },
  EINKAUF4: {
    host: 'einkauf4.wiso-tech-services.de',
    protocol: 'https://',
    ws: 'wss://',
    name: 'einkauf4',
    email: 'bonifaz-lioba@mindsetsolution.de'
  },
  EINKAUF5: {
    host: 'villaluna.mindsetsolution.de',
    protocol: 'https://',
    ws: 'wss://',
    name: 'einkauf5',
    email: 'villaluna@mindsetsolution.de'
  },
  EINKAUF6: {
    host: 'mindshift.mindsetsolution.de',
    protocol: 'https://',
    ws: 'wss://',
    name: 'einkauf6',
    email: 'mindshift@mindsetsolution.de'
  },
  EINKAUF7: {
    host: 'bfmt.mindsetsolution.de',
    protocol: 'https://',
    ws: 'wss://',
    name: 'einkauf7',
    email: 'bfmt@mindsetsolution.de'
  },
  EINKAUF8: {
    host: 'littlebigfuture.mindsetsolution.de',
    protocol: 'https://',
    ws: 'wss://',
    name: 'einkauf8',
    email: 'littlebigfuture@mindsetsolution.de'
  },
  EINKAUF9: {
    // host: 'einkauf9.wiso-tech-services.de',
    host: 'businessbynature.mindsetsolution.de',
    protocol: 'https://',
    ws: 'wss://',
    name: 'einkauf9',
    email: 'bbn@mindsetsolution.de'
  },
  EINKAUF10: {
    // host: 'einkauf10.wiso-tech-services.de',
    host: 'family-friends.mindsetsolution.de',
    protocol: 'https://',
    ws: 'wss://',
    name: 'einkauf10',
    email: 'family-friends@mindsetsolution.de'
  }
};

export const TOKEN_STORAGE = 'kiku';
export const GROUPS_STORAGE = 'kiku.groups';

