import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SettingsComponent } from './settings.component';
import { RouteGuard } from '../route.guard';
import { AclComponent } from './acl/acl.component';

const routes: Routes = [ {
  path: '',
  canActivate: [ RouteGuard ],
  children: [
    {
      path: '',
      component: SettingsComponent,
    }, {
      path: 'shop',
      loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule),
    }, {
      path: 'users',
      loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
    }, {
      path: 'accounts',
      // use this path to not have the same path as accounts in app.routing.ts
      loadChildren: () => import('../settings/accounts/accounts.module').then(m => m.AccountsModule),
    }, {
      path: 'acl',
      component: AclComponent,
    },
  ],
} ];

@NgModule( {
  imports: [ RouterModule.forChild( routes ) ],
  exports: [ RouterModule ],
} )
export class SettingsRoutingModule {
}
