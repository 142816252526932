import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PasswordChangeComponent } from '../shared/components/password-change/password-change.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../core/auth/auth.service';
import { UserGroup } from '../authentication/authentication.reducer';
import { Observable } from 'rxjs';
import { StorageService } from '../core/storage/storage.service';
import { Store } from '@ngrx/store';
import { UserService } from '../shared/services/user/user.service';
import { SetCategories } from '../einkauf/shop-product-list/shop-product-list.action'

import * as fromRoot from './../app.reducer';

@Component( {
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [ './dashboard.component.scss' ],
} )
export class DashboardComponent {
  public user$: Observable<UserGroup | string>;
  public userGroup = UserGroup;
  public userName;
  public userGroupshow;

  constructor(
    private userService: UserService,
    protected authService: AuthService,
    private storageService: StorageService,
    protected dialog: MatDialog,
    private router: Router,
    private store: Store<fromRoot.State>
  ) {
    this.authService.userIdAndGroupObserver.subscribe(user => {
      this.userGroupshow = user.user_group;
    });
    this.userName = this.storageService.get("user_identifier");

    this.userGroupshow = this.storageService.get("user_group");
    this.user$ = this.store.select(fromRoot.getUserGroup);
    this.store.dispatch(new SetCategories([{}]));

    if (  this.authService.isAuthenticated() ) {
      this.redirectByGroups( [this.userGroupshow.__zone_symbol__value] );
    }
  }

  async ngOnInit() {
    const id:string = await this.getUserId();
    await this.userService.getUser(id);
  }

  async changePassword(): Promise<void> {
    await this.dialog.open( PasswordChangeComponent, {
      data: { user: await this.getUserId() },
      width: '400px',
    } );
  }

  private async getUserId() {
    const id = await this.getUserdataFromToken( this.authService.token );
    return id.id;
  }

  async getUserdataFromToken( token ) {
    let base64Url = token.split( '.' )[ 1 ];
    let base64 = base64Url.replace( '-', '+' ).replace( '_', '/' );
    return JSON.parse( atob( base64 ) );
  }
  async redirectByGroups( groups:any ) {
    if(groups[0] == 'lieferant') {
      return this.router.navigate( [ '/supplier/list' ] );
    }
    if(groups[0].length == 0) {
      return this.router.navigate( [ '/supplier/list' ] );
    }
   }
}
