import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/api/api.service';
import { Transaction } from '../../types';

@Injectable( {
  providedIn: 'root'
} )
export class TransactionService {

  constructor(
    protected api: ApiService
  ) {
  }

  protected async _call( action: string, data: any = {} ) {
    return await this.api.rpc( `crm/transaction/${ action }`, data );
  }

  async create( transaction: Transaction ) {
    return await this._call( 'create', transaction );
  }

  async changeStatus( id: string, status: 0 | 1 | 2 ) {
    return await this._call( 'changeStatus', { id, status } );
  }

  async get( location: string = null, account: string = null, start: Date = null, end: Date = null ) {
    let data: { location?: string, account?: string, start?: Date, end?: Date } = {};
    if ( location ) {
      data.location = location;
    }
    if ( account ) {
      data.account = account;
    }
    if ( start ) {
      data.start = start;
    }
    if ( end ) {
      data.end = end;
    }

    return await this._call( 'get', data );
  }

  async sums( location: string = null, account: string = null, start: Date = null, end: Date = null, search: string = '', indexPage: number = 0, pageSize: number = 12 ) {
    let data: { location?: string, account?: string, start?: Date, end?: Date, search?: string, indexPage?: number, pageSize?: number } = {};
    if ( location ) {
      data.location = location;
    }

    if ( account ) {
      data.account = account;
    }

    if ( start ) {
      data.start = start;
    }

    if ( end ) {
      data.end = end;
    }

    data.search = search;
    data.indexPage = indexPage;
    data.pageSize = pageSize;
    
    return await this._call( 'sums', data );
  }
}
