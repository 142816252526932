import { AuthActions,  SET_AUTHENTICATED, SET_UNAUTHENTICATED, SET_USERGROUP } from './authentication.action';

export enum UserGroup {
  super_admin = 'super_admin',
  admin = 'admin',
  einkauf = 'einkauf',
  user = 'user',
  lieferant = 'lieferant',
  admin_einkauf = 'admin/einkauf'
}

export interface State {
  isAutenticated: boolean;
  userGroup: UserGroup.super_admin | UserGroup.admin_einkauf | UserGroup.admin | UserGroup.einkauf | UserGroup.user | UserGroup.lieferant | string;
}

const initialState: State = {
  isAutenticated: false,
  userGroup: ''
};

export function authReducer(state = initialState, action: AuthActions) {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        isAutenticated: true
      };
    case SET_UNAUTHENTICATED:
      return {
        ...state,
        isAutenticated: false
      };
    case SET_USERGROUP:
      return {
        ...state,
        userGroup: action.payload
      };
    default:
      return state;
  }
}

export const getIsAuth = (state: State) => state.isAutenticated;
export const getUserGroup = (state: State) => state.userGroup;
