import { Injectable } from '@angular/core';
import { Supplier, SUPPLIER_TYPE } from '../../types';
import { ApiService } from '../../../core/api/api.service';

@Injectable( {
  providedIn: 'root',
} )
export class ShopSupplierService {
  constructor(
    protected api: ApiService,
  ) {
  }

  protected async _call( action: string, data: any = {} ) {
    return await this.api.rpc( `shop/supplier/${ action }`, data );
  }

  async get( _id: string ) {
    return await this._call( 'get', { _id } );
  }

  async list( filter: string = null, isEinkauf: boolean = false, indexPage: number = undefined, pageSize: number = undefined ) {
    return await this._call( 'list', filter ? { filter, isEinkauf, indexPage, pageSize } : { filter: '', isEinkauf, indexPage, pageSize } );
  }

  async listUserSupplier( filter: string = null ) {
    return await this._call( 'listUserSupplier', filter ? { filter } : {} );
  }

  async cache() {
    return await this._call('cache', {});
  }

  async create( supplier: Supplier ) {
    return await this._call( 'create', supplier );
  }

  async update( supplier: Supplier ) {
    return await this._call( 'edit', supplier );
  }

  async delete( _id: string ) {
    this._call( 'remove', { _id } );
  }
}
