
<div class="login-wrap">
  <div class="container__row">
    <div class="col-4 col-sm-4 col-md-offset-2 col-md-4 col-lg-offset-4 col-lg-4">
      <form class="full-width-form" (submit)="submit()" [formGroup]="form">
        <mat-card class="centered-title">
          <mat-card-header>
            <mat-card-title>Login</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div>
              <mat-form-field [ngClass]="{'error' : apiError}">
                <input matInput="matInput" type="email" placeholder="Benutzername" formControlName="mail"/>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field [ngClass]="{'error' : apiError}">
                <input matInput="matInput" type="password" placeholder="Passwort" formControlName="password"/>
              </mat-form-field>
            </div>
            <div>
              <!-- <mat-error *ngIf="form.invalid &amp;&amp; (form.dirty || submitted)">Bitte überprüfe deine Eingaben.</mat-error> -->
              <mat-error *ngIf="apiError">Benutzername oder Passwort sind nicht korrekt!
                <span class="icon-warning" mat-list-icon="mat-list-icon" *ngIf="apiError">
                  <svg height="24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"/></svg>
                </span>
              </mat-error>
              <mat-error *ngIf="azureError?.msg">{{azureError?.msg }}
                <span class="icon-warning" mat-list-icon="mat-list-icon" *ngIf="azureError">
                  <svg height="24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"/></svg>
                </span>
              </mat-error>
            </div>
          </mat-card-content>
          <mat-card-actions align="end"><a mat-button="mat-button" color="primary" [routerLink]="[&quot;/reset&quot;]">Passwort vergessen?</a>
            <button id="login-btn" type="submit" mat-raised-button="mat-raised-button" color="primary" [disabled]="isLoggingIn">Anmelden</button>
          </mat-card-actions>
        </mat-card>
      </form>
    </div>
  </div>
</div>