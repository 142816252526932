export type User = {
  _id?: string;
  identifier?: string;
  password?: string;
  fullname?: {
    firstname?: string;
    lastname?: string;
  };
  user_nr?: string;
  birthdate?: string;
  address?: {
    street: string;
    zip: string;
    city: string;
    type: string;
    order: number;
  }[];
  contact?: {
    phone?: string;
    mail: string;
    fax?: string;
    type: string;
    order: number;
  }[];
  groups?: string[];
  organisation?: string[];
  organisation_role?: string;
  gen_password?: boolean;
  toc?: Date[];
  refused?: Date;
};

export type LOCATION = {
  _id?: string;
  name: string;
  internal: string;
  mail: string;
  phone?: string;
  mobile?: string;
  customer_mobile?: {
    number: string
  }[];
  supplier_contact?: {
    _id: any,
    number: string,
    delivery_number: string,
    billing_number: string
  }[];
  address: {
    zip: string;
    city: string;
    street: string;
    description?: string;
    primary?: boolean;
    type?: number;
  }[];
  bill_address: {
    firma_name: string;
    zip: string;
    city: string;
    street: string;
    description?: string;
    primary?: boolean;
    type?: number;
  }[];
  status?: boolean;
  deleted?: boolean;
};

export type Account = {
  _id?: string,
  name: string,
  isDeliveryAccount?: boolean,

};

export type Category = {
  _id?: string,
  name: string,
  parent?: string,
  account: string,
  release: number,
  isChecked?: boolean,
  children?: Category[],
};

export type Transaction = {
  _id?: string,
  value: number,
  status: 0 | 1 | 2 | 3, // applied / pending / closed
  description?: string,
  account: string,
  location: string,
  date: Date,
  type: boolean
};

export enum SUPPLIER_TYPE {
  manually,
  rest,
}

export type Supplier = {
  _id?: string,
  name: string,
  contact: string,
  address: {
    street: string,
    zip: string,
    city: string
  },
  image?: string, // 'server1/products/images/foto1.png',
  images?: ImageObj[],
  phone: string,
  mail: string,
  order_mail: string,
  order_phone: string,
  delivery: number,
  delivery_free_value: string,
  min_order_value: number,
  graduationOrderValue: {}[],
  freight_exemption: boolean,
  shipping_costs: {}[],
  apply_shipping_cost: boolean,
  offer_period_order?: number,
  // type must be one of SUPPLIER_TYPE
  process_order: number,
  type: SUPPLIER_TYPE,
  status: boolean,
  preferred: boolean,
  nr_products?: any,
  deleted?: Date,
  updateName?: [
    {
      oldName: String,
      updatedBy: String,
      updatedAt: Date
    }
  ],
  termsAndConditions?: string;
}

export type SupplierCategoryItem = {
  supplier_id: string,
  supplier_category_list?: [
    {
      supplier_category_name: string,
      shop_category_id: string,
    }
  ],
  editedSupplierCategories? : []
}


export type Product = {
  _id?: string,
  sku: string, // 'A0001',
  name: string, // 'LILLYDOO WINDELN',
  supplier?: {
    _id: string,
    name: string
  }, // '5d8264368ab20d00187d6fba',
  manufacturer?: string, // 'Babywindeln & mehr',
  image?: string, // 'server1/products/images/foto1.png',
  images?: ImageObj[], // 'server1/products/images/foto1.png',
  description?: string, // 'Beschreibung des Produktes',
  price_brutto: number, // 500,
  price_netto?: number, // 400,
  vat: number, // 19,
  category?: {
    id: string,
    name: string,
    release: number,
    parents: [{
      id: string,
      name: string
    }]
  }, // '5d63e40caab5200011aa1246',
  status: boolean, // false,
  availability: boolean, // false,
  shipping_costs?: boolean, // false,
  shipping_costs_type: string, // '',
  // delivery_costs: number, // 45,
  // delivery_time: string, // '3 Tage',
  tag?: string, // '5dcbc62fb0d9870018348d79',
  qty: number, // 45
  deleted?: boolean,
  disableBasket?: boolean,
  cacheAll?: boolean
}

export type ImageObj = {
  imageUrl: string
}

export type CartItemInformation = {
  totalPrice: number,
  amountProductsInCart: number
}
export type CartItemInformationLocation = {
  totalPriceLocation: number,
  amountProductsInCartLocation: number
}

export type CartItem = {
  cart_id: string,
  category_id: string,
  createdAt: string,
  createdBy: string,
  deleted: false,
  location_id: string,
  product: Product[],
  location: Location[],
  supplier: Supplier[],
  product_id: string,
  qty: number,
  flag: number,
  supplierFlag: number,
  supplier_category_id: string,
  supplier_id: string,
  _id: string,
  cart: any,
  completedOrder?: boolean,
  transactionsBalance: number,
  checkedByEinkauf: boolean,
  acceptedByEinkauf: boolean,
  acceptedBySupplier: boolean,
  infoOrder: string,
  dateCreate: Date,
  price: number,
  editBySupplier: any[],
  account: {
    _id: string,
    name: string
  }
}

export type ProductIntoCartItem = {
  cart_id?: string,
  product_id: string,
  supplier_id?: string,
  location_id?: string,
  category_id?: string,
  supplier_category_id?: string,
  qty: number,
}

export type OrderItem = {
  qty: number,
  product: string,
  product_name: string,
  location: string,
  unit_price: number,
  total_price: number,
  category: string,
  category_name: string,
  category_release: number,
  supplier: string,
  supplier_name: string,
  account: string,
  account_name: string,
  account_balance: number,
}

export interface AzureAuthError {
  msg: string;
  status?: string;
}

export interface ParsedProductFromCsv {
  sku: string;
  productName: string;
  manufacturer: string;
  images: Array<string>;
  description: string;
  priceBrutto: number;
  vat: number;
  supplierCategory: string;
  status: boolean;
  availability: boolean;
  qty: number;
  shippingCost: boolean;
  shippingCostType?: string;
  variant: string;
  variantAttribute1?: string;
  variantAttribute1Value?: string;
  variantAttribute2?: string;
  variantAttribute2Value?: string;
  variantAttribute3?: string;
  variantAttribute3Value?: string;
  variantAttribute4?: string;
  variantAttribute4Value?: string;
}