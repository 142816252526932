
<mat-card>
  <mat-card-header>
    <mat-card-title>Add Allow Rule</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form (submit)="submitAllow()" [formGroup]="allowForm">
      <div class="mdc-layout-grid">
        <div class="mdc-layout-grid__inner">
          <div class="mdc-layout-grid__cell">
            <mat-form-field>
              <input matInput="matInput" type="text" required="required" placeholder="Role" formControlName="group"/>
            </mat-form-field>
          </div>
        </div>
        <div class="mdc-layout-grid__inner">
          <div class="mdc-layout-grid__cell">
            <mat-form-field>
              <input matInput="matInput" type="text" required="required" placeholder="Path" formControlName="path"/>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="mdc-layout-grid">
        <div class="mdc-layout-grid__inner">
          <div class="mdc-layout-grid__cell">
            <button mat-raised-button="mat-raised-button" color="primary">Speichern</button>
          </div>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
<mat-card>
  <mat-card-header>
    <mat-card-title>Add Deny Rule</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form (submit)="submitDeny()" [formGroup]="denyForm">
      <div class="mdc-layout-grid">
        <div class="mdc-layout-grid__inner">
          <div class="mdc-layout-grid__cell">
            <mat-form-field>
              <input matInput="matInput" type="text" required="required" placeholder="Role" formControlName="group"/>
            </mat-form-field>
          </div>
        </div>
        <div class="mdc-layout-grid__inner">
          <div class="mdc-layout-grid__cell">
            <mat-form-field>
              <input matInput="matInput" type="text" required="required" placeholder="Path" formControlName="path"/>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="mdc-layout-grid">
        <div class="mdc-layout-grid__inner">
          <div class="mdc-layout-grid__cell">
            <button mat-raised-button="mat-raised-button" color="primary">Speichern</button>
          </div>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>