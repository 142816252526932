import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiService } from './api/api.service';
import { AuthService } from './auth/auth.service';
import { StorageService } from './storage/storage.service';
import { HttpClientModule } from "@angular/common/http";

@NgModule( {
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    ApiService,
    AuthService,
    StorageService
  ]
} )
export class CoreModule {
}
