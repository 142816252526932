import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as fromRoot from './../app.reducer';
import { Observable } from 'rxjs';
import { User } from '../shared/types';
import { UserGroup } from '../authentication/authentication.reducer';
import { Store } from '@ngrx/store';
import { StorageService } from '../core/storage/storage.service';
import { UserService } from '../shared/services/user/user.service';
import { AuthService } from '../core/auth/auth.service';

@Component( {
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: [ './settings.component.scss' ],
} )
export class SettingsComponent implements OnInit {
  public user$: Observable<UserGroup | string>;
  public user: User = null;
  public userGroup = UserGroup;
  navLinks: { label: string, link: string }[];
  activeLinkIndex: number = -1;
  public userName;
  public userGroupshow;

  constructor(
     private router: Router,
     private userService: UserService,
     protected authService: AuthService,
     private storageService: StorageService,
     private store: Store<fromRoot.State>
     
    ) {
    this.navLinks = [
      {
        label: 'Subkonten',
        link: './account',
      }, {
        label: 'Daueraufträge',
        link: './standing-order',
      }, {
        label: 'Bezeichnungen',
        link: './tag',
      }, {
        label: 'Spezialfaktoren',
        link: './special-factor',
      }, {
        label: 'Shop',
        link: './shop',
      },
    ];
    this.authService.userIdAndGroupObserver.subscribe(user => {
      this.userGroupshow = user.user_group;
    });
    this.userName = this.storageService.get("user_identifier");

    this.userGroupshow = this.storageService.get("user_group");
    this.user$ = this.store.select(fromRoot.getUserGroup);

    if (  this.authService.isAuthenticated() ) {
      
      this.redirectByGroups( [this.userGroupshow.__zone_symbol__value] );
    }
  }
  

  async ngOnInit() {
    const user = await this.getUser();
    this.router.events.subscribe( ( res ) => {
      this.activeLinkIndex = this.navLinks.indexOf( this.navLinks.find( tab => tab.link === '.' + this.router.url ) );
    } );
  }

  private async getUser() {
    let userId = await this.getUserId();
    if (!this.user || this.user._id === userId) {
      this.user = await this.userService.getUser(userId);
    }

    return this.user;
  }
  
  private async getUserId() {
    const id = await this.getUserdataFromToken( this.authService.token );
    return id.id;
  }

  async getUserdataFromToken( token ) {
    let base64Url = token.split( '.' )[ 1 ];
    let base64 = base64Url.replace( '-', '+' ).replace( '_', '/' );
    return JSON.parse( atob( base64 ) );
  }
  async redirectByGroups( groups ) {
    
    if(groups[0] == 'lieferant') {
      return this.router.navigate( [ '/supplier/list' ] );
    }
    if(groups[0] == 'user') {
      return this.router.navigate( [ '/dashboard' ] );
    }
    if(groups[0].length === 0) {
      return this.router.navigate( [ '/supplier/list' ] );
    }
  }
}
