<div class="supplier-delivery">
  <h5 class="supplier-delivery__title">Lieferkosten</h5>
  <form [formGroup]="form">
    <div mat-dialog-content="mat-dialog-content">
      <h6 class="supplier-delivery__subtitle">Versandkosten:</h6>
      <div class="supplier-delivery__content">
        <span class="supplier-delivery__content__label"> Versandkostenpauschale (0€ für keine):</span>
        <input class="supplier-delivery__content__input" type="number" min="0" ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.001" formControlName="delivery"><span class="number-symbol" matSuffix="matSuffix">€</span>
      </div>

      <div class="supplier-delivery__content">
        <span class="supplier-delivery__content__label"> Versandkostenfreiheit ab Gesamtbestellwert von:</span>
        <input class="supplier-delivery__content__input" 
          matinput type="number" min="0" 
          ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" 
          step="0.001" 
          formControlName="delivery_free_value"
        >
        <span class="number-symbol" matSuffix="matSuffix">€</span>
      </div>

      <h6 class="supplier-delivery__subtitle">Frachtkosten:</h6>
      <div class="supplier-delivery__content" formArrayName="graduationOrderValue">
        
        <h6 class="supplier-delivery__content__label">Staffellung Auftragswert:</h6>
        <div class="supplier-delivery__shipping" *ngFor="let value of graduationOrderValues.controls; let i = index" [formGroupName]="i">

          <span class="supplier-delivery__shipping__title">ab</span>
          <input class="supplier-delivery__content__input" 
            type="number" min="0" 
            ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" 
            step="0.001" 
            onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : (event.charCode >= 48 && event.charCode <= 57 || event.charCode == 44)"
            formControlName="from">
          <span class="number-symbol-ab" matSuffix="matSuffix">€</span>
          <span class="supplier-delivery__shipping__midletitle">Frachtkosten i.H.v</span>
          <input class="supplier-delivery__content__input" 
            type="number" min="0" 
            ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" 
            step="0.001" 
            formControlName="percent"
          >
          <span class="number-symbol-percent" matSuffix="matSuffix">%</span>

          <button mat-flat-button color="warn" [disabled]="i==0" class="mat-elevation-z0 button-remove-shipping" (click)="removeGraduationOrderValues(i)">
            <span class="icon-remove icon-remove--white">
              <svg height="24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 13H5v-2h14v2z"/></svg>
            </span>
          </button>
        </div>
      </div>
      <div class="supplier-delivery__content">
        <div class="supplier-delivery-shipping-button-wrapper">
          <button
            mat-flat-button
            [disabled]="false"
            color="primary"
            class="mat-elevation-z0 button-remove-add-wrapper"
            (click)="addGraduationOrderValue()">         <span class="icon-add">
          <svg height="24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>
        </span></button>
        </div>
      </div>

      <div class="supplier-delivery__content">
        <span class="supplier-delivery__content__label"> Frachtkostenfreiheit:</span>
        <mat-form-field>
          <mat-select formControlName="freight_exemption">
            <mat-option [value]="0">Ja</mat-option>
            <mat-option [value]="1">Nein</mat-option>
          </mat-select>
          <mat-error *ngIf="form.hasError(&quot;required&quot;, &quot;freight_exemption&quot;)">Der Bestellprozess muss angegeben werden.</mat-error>
        </mat-form-field>
      </div>
      <div class="supplier-delivery__content" formArrayName="shipping_costs">
        
        <h6 class="supplier-delivery__content__label">Staffellung Auftragswert:</h6>
        <div class="supplier-delivery__shipping" *ngFor="let value of shipping_costs.controls; let i = index" [formGroupName]="i">

          <span class="supplier-delivery__shipping__title">ab</span>
          <input class="supplier-delivery__content__input" 
            type="number" min="0" 
            ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" 
            step="0.001" 
            onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : (event.charCode >= 48 && event.charCode <= 57 || event.charCode == 44)"
            formControlName="shipping_costs_price"
          >
          <span class="number-symbol-ab" matSuffix="matSuffix">€</span>

          <span class="supplier-delivery__shipping__midletitle">f&uuml;r</span>
          <input class="supplier-delivery__content__input" 
            type="text"
            formControlName="shipping_costs_type"
          >
          <button mat-flat-button color="warn" [disabled]="i==0 || disableShippingCost" class="mat-elevation-z0 button-remove-shipping" (click)="removeShippingCost(i)">
            <span class="icon-remove icon-remove--white">
              <svg height="24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 13H5v-2h14v2z"/></svg>
            </span>
          </button>
        </div>
      </div>
      <div class="supplier-delivery__content">
        <div class="supplier-delivery-shipping-button-wrapper">
          <button
            mat-flat-button
            [disabled]="disableShippingCost"
            color="primary"
            class="mat-elevation-z0 button-remove-add-wrapper"
            (click)="addShippingCost()">         <span class="icon-add">
          <svg height="24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>
        </span></button>
        </div>
      </div>

      <div class="supplier-delivery__content">
        <span class="supplier-delivery__content__label"> Versandkosten entfallen, sobald Frachtkosten anfallen?</span>
        <mat-form-field>
          <mat-select formControlName="apply_shipping_cost">
            <mat-option [value]="0">Ja</mat-option>
            <mat-option [value]="1">Nein</mat-option>
          </mat-select>
          <mat-error *ngIf="form.hasError(&quot;required&quot;, &quot;apply_shipping_cost&quot;)">Der Bestellprozess muss angegeben werden.</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div mat-dialog-actions="mat-dialog-actions" align="end">
      <a mat-button="mat-button" (click)="dialogRef.close( false )">Abbrechen</a>
      <button mat-raised-button="mat-raised-button" type="submit" color="primary" (click)="dialogRef.close( form )">Speichern</button>
    </div>
  </form>
</div>