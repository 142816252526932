import { ApiService } from './../../../core/api/api.service';
import { Injectable } from '@angular/core';
import { Product } from '../../types';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    protected api: ApiService,
  ) {
  }

  protected async _call(action: string, data: any = {}) {
    return await this.api.rpc(`shop/product/${action}`, data);
  }

  async create(product: Product) {
    return await this._call('create', product);
  }

  async update(product: Product) {
    return await this._call('edit', product);
  }

  async uploadCSV(info: Array<{}> | any, supplier_id, typeOfData: string = '', lastItems: boolean = false) {
    return await this._call('uploadCSV', { info, supplier_id, typeOfData, lastItems });
  }

  async get(_id: string, cart_id: string, inactive_products: boolean) {
    return await this._call('get', { _id, cart_id, inactive_products });
  }

  async checkVariant(_id: string = '', variant_attributes: { attribute: string, value: string }[] = [] ) {
    return await this._call('checkVariant',  { _id, variant_attributes });
  }

  async listVariant(_id: string = '', search: string = null, page_index: number = 0, page_size: number = 12, variant:string = "", search_price = {}, onlyActiveProduct:number = 1, search_variant_attribute: string = "" ) {
    return await this._call('listVariant', search ? { _id, search, page_index, page_size, variant, search_price, onlyActiveProduct, search_variant_attribute } : { _id, search: "", page_index, page_size, variant, search_price, onlyActiveProduct, search_variant_attribute });
  }

  async list(supplier_id: string = '', search: string = null, inactive_products: boolean, page_size: number = 12, page_index: number = 0) {
    return await this._call('list', search ? { supplier_id, search, inactive_products, page_size, page_index } : { supplier_id, search: "", inactive_products, page_size, page_index });
  } 

  async listFilter(supplier_id: string, name: string = null, category_id = null, price_sort: number = 0, page_size: number = 12, page_index: number = 0) {
    return await this._call('listFilter', { supplier_id, name, category_id, price_sort, page_size, page_index });
  }

  async delete(_id: string) {
    this._call('remove', { _id });
  }

  async cache() {
    return await this._call('cache', {});
  }
}
