<mat-sidenav-container autosize="false" fullscreen="fullscreen" [class.unauthorized]="!authService.authenticated">
  <mat-sidenav class="mobile-navigation" *ngIf="authService.authenticated" #sidenav="" mode="over">
    <mat-nav-list>
      <a mat-list-item="mat-list-item" *ngIf="(user$ | async) !== userGroup.lieferant" id="nav_home"
        [routerLink]="[&quot;/dashboard&quot;]" routerLinkActive="active" (click)="sidenav.close()">Home</a>
      <a mat-list-item="mat-list-item" *ngIf="(user$ | async) !== userGroup.lieferant"
        [routerLink]="[&quot;/einkauf&quot;]" routerLinkActive="active" id="nav_einkauf"
        (click)="sidenav.close()">Einkauf</a>
      <a *ngIf="(user$ | async) !== userGroup.user && (user$ | async) !== userGroup.lieferant "
        mat-list-item="mat-list-item" [routerLink]="[&quot;/locations&quot;]" routerLinkActive="active"
        id="nav_location" (click)="sidenav.close()">Standorte</a>
      <a *ngIf="(user$ | async) !== userGroup.user && (user$ | async) !== userGroup.lieferant"
        mat-list-item="mat-list-item" [routerLink]="[&quot;/accounts&quot;]" routerLinkActive="active" id="nav_account"
        (click)="sidenav.close()">Budget</a>
      <a *ngIf="(user$ | async) !== userGroup.user && (user$ | async) !== userGroup.lieferant" id="nav_settings"
        mat-list-item="mat-list-item" [routerLink]="[&quot;/settings&quot;]" routerLinkActive="active"
        (click)="sidenav.close()">Einstellungen</a>
      <a *ngIf="(user$ | async) == userGroup.einkauf" id="nav_freigaben" mat-list-item="mat-list-item"
        [routerLink]="[&quot;/einkaufbasket&quot;]" routerLinkActive="active" (click)="sidenav.close()">Freigaben</a>
      <a *ngIf="(user$ | async) == userGroup.user" id="nav_basket" class="basket-menu" mat-list-item="mat-list-item"
        [routerLink]="[&quot;/basket&quot;]" routerLinkActive="active" (click)="sidenav.close()">
        <span class="icon-shopping-cart">
          <svg height="24" width="24" fill="#FFF"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49A1.003 1.003 0 0 0 20 4H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"/></svg>
        </span>
        <span class="icon-qty">{{totalProductsInBasket}}</span>
          
        <span>{{ totalBasketPrice | currency:'EUR' }}</span>
      </a>
      <!-- <a matRipple="matRipple"
      matRippleColor="rgba(255,255,255,.15)" 
      class="basket-link-container">
      <span>{{ userName }}</span>
      <span>Rolle: {{ userGroupshow }}</span>
       </a> -->

      <mat-divider></mat-divider>
      <a id="logout" class="logout" mat-list-item="mat-list-item" (click)="sidenav.close();logout()">
        <span>Logout</span>
        <span class="icon-exit-to-app">
          <svg height="24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M10.09 15.59 11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5a2 2 0 0 0-2 2v4h2V5h14v14H5v-4H3v4a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/></svg>
        </span>
      </a>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="bg-wrap"
      *ngIf="!authService.authenticated && (url != '/impressum'  && url != '/datenschutz' && url != '/agb')"></div>
    <div [ngClass]="{ 'sticky-wrap' : true, 'sticky-wrap-impressum' : (url == '/impressum' && !authService.authenticated) }">
      <header>
        <mat-toolbar *ngIf="authService.authenticated" color="accent" [ngClass]="{ einkauf : environment == 'einkauf', einkauf8 : environment == 'einkauf8'}"
          id="ColorToolbar">
          <div class="container" *ngIf="(user$ | async)">
            <a class="title" *ngIf="(user$ | async) !== userGroup.lieferant" [routerLink]="[&quot;/&quot;]">
              {{ environment != "einkauf" && environment != "einkauf8" ? "Einkaufsplattform" : "" }}
              <img *ngIf="environment == 'einkauf'" class="einkauf-logo" src="../assets/img/Kinderzentren-Logo.svg"
                alt="Logo">
              <img *ngIf="environment == 'einkauf8'" class="einkauf-logo" src="../assets/img/Logo_lbF_weiß Transparent.svg"
                alt="Logo">
            </a>
            <a class="title" *ngIf="(user$ | async) === userGroup.lieferant" href="/supplier/list">Einkaufsplattform</a>
            <div class="spacer"></div>
            <a *ngIf="(user$ | async) !== userGroup.lieferant" matRipple="matRipple"
              matRippleColor="rgba(255,255,255,.15)" [routerLink]="[&quot;/basket&quot;]" routerLinkActive="active"
              class="basket-link-container-mobile">
              <span class="icon-shopping-cart">
                <svg height="24" width="24" fill="#FFF"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49A1.003 1.003 0 0 0 20 4H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"/></svg>
              </span>
              <span class="icon-qty">{{totalProductsInBasket}}</span>
                
              <span>{{ totalBasketPrice | currency:'EUR' }}</span>
            </a>
            <a class="hide-from-medium" (click)="sidenav.open()">
              <span class="icon-menu"><svg height="24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/></svg></span>
            </a>
            <div class="desktop-navigation">
              <nav class="main-navigation">
                <ul class="clearfix" *ngIf="(user$ | async)">
                  <li *ngIf="(user$ | async) !== userGroup.lieferant"><a matRipple="matRipple" id="nav_home" class="nav-item-title"
                      matRippleColor="rgba(255,255,255,.15)" [routerLink]="[&quot;/dashboard&quot;]"
                      routerLinkActive="active">Home</a></li>
                  <li *ngIf="(user$ | async) !== userGroup.lieferant"><a matRipple="matRipple" id="nav_einkauf" class="nav-item-title"
                      matRippleColor="rgba(255,255,255,.15)" [routerLink]="[&quot;/einkauf&quot;]"
                      routerLinkActive="active">Einkauf</a></li>
                  <li *ngIf="(user$ | async) !== userGroup.lieferant && (user$ | async) !== userGroup.user "><a
                      matRipple="matRipple" matRippleColor="rgba(255,255,255,.15)" id="nav_location" class="nav-item-title"
                      [routerLink]="[&quot;/locations&quot;]" routerLinkActive="active">
                      {{((user$ | async) !== userGroup.user) ? "Standorte" : "Standort"}}
                    </a></li>
                  <li *ngIf="(user$ | async) !== userGroup.lieferant "><a matRipple="matRipple" id="nav_account" class="nav-item-title"
                      matRippleColor="rgba(255,255,255,.15)" [routerLink]="[&quot;/accounts&quot;]"
                      [ngClass]="{'active' : (this.urlParams && this.urlParams[1] == 'locations' && ((user$ | async) == userGroup.user))}"
                      routerLinkActive="active"> {{ ((user$ | async) !== userGroup.user) ? "Budget" : "Budget" }} </a>
                  </li>
                  <li
                    *ngIf="(user$ | async) !== userGroup.lieferant && (user$ | async) !== userGroup.user && (user$ | async) !== userGroup.einkauf">
                    <a matRipple="matRipple" matRippleColor="rgba(255,255,255,.15)" id="nav_settings" class="nav-item-title"
                      [routerLink]="[&quot;/settings&quot;]" routerLinkActive="active">Einstellungen</a>
                  </li>
                  <li *ngIf="(user$ | async) === userGroup.user"><a matRipple="matRipple"
                      matRippleColor="rgba(255,255,255,.15)" id="nav_my-basket" [routerLink]="[&quot;/userbasket&quot;]" class="nav-item-title"
                      routerLinkActive="active">Meine Bestellungen</a></li>
                  <li *ngIf="(user$ | async) == userGroup.einkauf || (user$ | async) == userGroup.admin_einkauf"><a matRipple="matRipple" class="nav-item-title"
                      matRippleColor="rgba(255,255,255,.15)" [routerLink]="[&quot;/einkauforders&quot;]"
                      routerLinkActive="active">Bestellübersicht/Freigabe</a></li>
                  <li *ngIf="(user$ | async) == userGroup.user"><a matRipple="matRipple" id="nav_basket"
                      matRippleColor="rgba(255,255,255,.15)" [routerLink]="[isBasketDisabled ? &quot;/sonderbestellung&quot; : &quot;/basket&quot;]"
                      routerLinkActive="active" class="basket-link-container">
                      <span class="icon-shopping-cart">
                        <svg height="24" width="24" fill="#FFF"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49A1.003 1.003 0 0 0 20 4H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"/></svg>
                      </span>
                      <span class="icon-qty">{{totalProductsInBasket}}</span>
                        
                      <span>{{ totalBasketPrice | currency:'EUR' }}</span>
                    </a></li>
                  <li><a class="logout nav-item-title" matRipple="matRipple" matRippleColor="rgba(255,255,255,.15)" id="logout" 
                      (click)="logout()"><span>Logout</span>
                      <span class="icon-exit-to-app">
                        <svg height="24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M10.09 15.59 11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5a2 2 0 0 0-2 2v4h2V5h14v14H5v-4H3v4a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/></svg>
                      </span>
                    </a></li>
                </ul>
              </nav>
            </div>
          </div>
        </mat-toolbar>
      </header>
      <main>
        <!-- <div [ngClass]="{container: (url != '/einkauforders' && (urlParams && urlParams[1] != 'einkauforders')), 'container-fluid': (url === '/einkauforders' || (urlParams && urlParams[1] == 'einkauforders'))}"> -->
        <div class="container" >
          <router-outlet></router-outlet>
        </div>
      </main>
      <app-footer></app-footer>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>