import { Injectable } from '@angular/core';
import { SERVERS } from './constants';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  private _currentServer;

  constructor() {
    switch (window.location.host) {
      case "einkaufsplattform.stage.slice-dice.de":
        this._currentServer = SERVERS['STAGE'];
        break;

      case "kiku-test.wiso-tech-services.de":
        this._currentServer = SERVERS['TESTWT'];
        break;

      case "kiku-test.wiso-tech-services.de":
        this._currentServer = SERVERS['KIKUTEST'];
        break;

      case "kiku.wiso-tech-services.de":
        this._currentServer = SERVERS['KIKU'];
        break;

      case "kiku.de":
        this._currentServer = SERVERS['LIVE'];
        break;

      case "kiku-autotest.wiso-tech-services.de":
        this._currentServer = SERVERS['KIKUAUTOTEST'];
        break;

      case "kiku3.wiso-tech-services.de":
        this._currentServer = SERVERS['KIKU3'];
        break;
      case "kiku.mindsetsolution.de":
      case "einkauf.wiso-tech-services.de":
      case "einkauf.kiku.wiso-tech-services.de":
        this._currentServer = SERVERS['EINKAUF'];
        break;

      case "localhost":
      case "localhost:4200":
        this._currentServer = SERVERS['DEV'];
        break;

      case "einkauf2.wiso-tech-services.de":
      case "einkauf2.kiku.wiso-tech-services.de":
        this._currentServer = SERVERS['EINKAUF2'];
        break;

      case "einkauf3.wiso-tech-services.de":
      case "einkauf3.kiku.wiso-tech-services.de":
      case "test.mindsetsolution.de":
        this._currentServer = SERVERS['EINKAUF3'];
        break;

      case "bonifaz-lioba.mindsetsolution.de":
      case "einkauf4.wiso-tech-services.de":
      case "einkauf4.kiku.wiso-tech-services.de":
        this._currentServer = SERVERS['EINKAUF4'];
        break;

      case "villaluna.mindsetsolution.de":
      case "einkauf5.wiso-tech-services.de":
      case "einkauf5.kiku.wiso-tech-services.de":
        this._currentServer = SERVERS['EINKAUF5'];
        break;

      case "mindshift.mindsetsolution.de":
      case "einkauf6.wiso-tech-services.de":
      case "einkauf6.kiku.wiso-tech-services.de":
        this._currentServer = SERVERS['EINKAUF6'];
        break;

      case "einkauf7.wiso-tech-services.de":
      case "einkauf7.kiku.wiso-tech-services.de":
      case "bfmt.mindsetsolution.de":
        this._currentServer = SERVERS['EINKAUF7'];
        break;

      case "einkauf8.wiso-tech-services.de":
      case "einkauf8.kiku.wiso-tech-services.de":
      case "littlebigfuture.mindsetsolution.de":
        this._currentServer = SERVERS['EINKAUF8'];
        break;

      case "einkauf9.wiso-tech-services.de":
      case "einkauf9.kiku.wiso-tech-services.de":
      case "businessbynature.mindsetsolution.de":
        this._currentServer = SERVERS['EINKAUF9'];
        break;

      case "einkauf10.wiso-tech-services.de":
      case "einkauf10.kiku.wiso-tech-services.de":
      case "family-friends.mindsetsolution.de":
        this._currentServer = SERVERS['EINKAUF10'];
        break;

      default:
        break;
    }

    if (!environment.production) {
      console.log("environment is ", this._currentServer);
    }
  }

  public get SERVER() {
    return this._currentServer;
  }

}
