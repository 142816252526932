import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormArray,
  FormControl,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AuthService } from "src/app/core/auth/auth.service";
import { StorageService } from "../../core/storage/storage.service";
import { AccountService } from "../../shared/services/account/account.service";
import { CartService } from "../../shared/services/cart/cart.service";
import { OrderService } from '../../shared/services/order/order.service'
import { Account } from "../../shared/types";
import { ConfirmComponent } from '../../shared/components/confirm/confirm.component';
import { NotificationService } from "src/app/shared/services/notification/notification.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import groupBy from 'lodash/groupBy';
import { environment } from "src/environments/environment";

@Component({
  selector: "app-special-order",
  templateUrl: "./special-order.component.html",
  styleUrls: ["./special-order.component.scss"],
})
export class SpecialOrderRoutingComponent implements OnInit {
  public deliveryCost: number = 0;
  public subTotal: number = 0;
  public form: FormGroup;
  public productsArray: FormArray;
  public accountOptions: Account[] = [];
  public hasSpecialOrder: boolean = false;
  public disableButtons: boolean = false;
  public products: any[] = [];
  public accounts: any;
  public name: string;
  public isDeliveryCostFilled = false;

  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private router: Router,
    private cartService: CartService,
    private orderService: OrderService,
    private accountService: AccountService,
    private authService: AuthService,
    private dialog: MatDialog,
    private notification: NotificationService,
    private ngxService: NgxUiLoaderService,
  ) {
    if (environment.specialOrderIsDisabled) {
      this.notification.showSnackbar('Sonderbestellungen sind noch nicht möglich', 'OK');
      this.router.navigate(['einkauf']);
    }
  }

  async ngOnInit() {
    this.storageService.get("user_group").then(role => {
      if (role !== "user") {
        this.router.navigate(["/"]);
        return;
      }
    }).catch(err => console.dir(err));

    this.form = this.formBuilder.group({
      comment: [""],
      deliveryCost: [null],
      name: new FormControl("", [Validators.required]),
      products: this.formBuilder.array([]),
    });

    this.productsArray = this.form.controls["products"] as FormArray;
    this.productsArray.push(
      new FormGroup({
        sku: new FormControl("", []),
        name: new FormControl("", [Validators.required]),
        priceBrutto: new FormControl(0, [Validators.required, Validators.pattern(/[^0]+/)]),
        qty: new FormControl(1, [Validators.required]),
        price: new FormControl({ value: 0, disabled: true}, Validators.required),
        budget: new FormControl("", [Validators.required]),
        linkProduct: new FormControl("", []),
        comment: new FormControl(""),
      })
    );

    const cart_id = await this.storageService.get('cart_id');

    const cartItems = await this.orderService.get(cart_id);
    if (cartItems.orders?.length > 0 && cartItems.orders[0].flag !== 5) {
      // this.router.navigate([''])
    }
    // const accounts = this.accountService.list()

    const { accounts } = await this.accountService.list();

    this.accountOptions = accounts;

    this.productsArray.controls.map((productControl) => {
      productControl.get("priceBrutto").valueChanges.subscribe((value) => {
        productControl.get("price").setValue(value * productControl.get("qty").value);
        this.calcSubTotal();
      });

      productControl.get("qty").valueChanges.subscribe((value) => {
        productControl.get("price").setValue(
          value * productControl.get("priceBrutto").value
        );
        this.calcSubTotal();
      });
    });

    this.form.get("deliveryCost").valueChanges.subscribe((value) => {
      this.deliveryCost = Number(value);
    });

    const userId = await this.cartService.getCartId(await this.getUserId());
    const cartData = await this.cartService.list(userId);

    if (Array.isArray(cartData) && cartData.length === 0) return;
    if (cartData?.cartItem?.length === 0) return;

    const specialOrderDetails = cartData.cartItem[0]?.cart[0]?.special_order_details;
    if (!specialOrderDetails) return;
    
    this.hasSpecialOrder = true;    
    this.notification.showSnackbar('Es liegt aktuell eine Bestellung zur Freigabe beim Einkauf, bis zur Annahme/Ablehnung dieser können Sie keine Sonderbestellung absenden.', 'OK', 5500);
    
    this.form.patchValue({
      name: specialOrderDetails.name,
      comment: specialOrderDetails.comment,
      deliveryCost: specialOrderDetails.deliveryCost
    })
    
    const cartProducts = specialOrderDetails.products;

    this.name = specialOrderDetails.name
    this.products = cartProducts;
    this.accounts = groupBy(accounts, account => account._id);
    let subTotal = 0;
    for (let product of this.products) {
      subTotal += product.priceBrutto * product.qty
    }
    this.subTotal = subTotal
  }

  onKeydownLK(event) {
    var charCode = (event.which) ? event.which : event.onKeydown;
    return !(charCode > 31 && (charCode < 48 || charCode > 57 || event.charCode == 44));
  }
  
  goBack(): void {
    this.location.back();
  }

  calcSubTotal() {
    const currentProducts = this.form.get("products").value;
    let subTotal: number = 0;
    for (const product of currentProducts) {
      subTotal += product.priceBrutto * +product.qty;
    }
    this.subTotal = parseFloat(subTotal.toFixed(2));
  }

  onRemove(index: number): void {
    if (this.hasSpecialOrder || this.productsArray.length === 1) return;

    this.productsArray.removeAt(index);
  }

  onAddQuantity(index?: number): void {
    if (this.hasSpecialOrder) return;

    const currentValue = this.form.get("products").value[index]["qty"];
    const product = (<FormArray>this.form.get("products")).at(index);
    product.patchValue({
      qty: +(+currentValue + 1).toFixed(0),
    });
    this.calcSubTotal();
  }

  onSubtractQuantity(index?: number): void {
    if (this.hasSpecialOrder) return;

    const currentValue = this.form.get("products").value[index]["qty"];
    if (currentValue <= 1) return;

    const productsFormArray = (<FormArray>this.form.get("products")).at(index);
    productsFormArray.patchValue({
      qty: +(+currentValue - 1).toFixed(0),
    });
    this.calcSubTotal();
  }

  onAddNewProduct(): void {
    if (this.hasSpecialOrder) return;
    const newFormGroup = this.formBuilder.group({
      sku: ["", []],
      name: ["", [Validators.required]],
      priceBrutto: [0, [Validators.required, Validators.pattern(/[^0]+/)]],
      qty: [1, [Validators.required]],
      budget: ["", [Validators.required]],
      price: [{ value: 0, disabled: true }, []],
      linkProduct: ["", []],
      comment: [""],
    });

    this.productsArray.push(newFormGroup);

    this.productsArray.controls.map((productControl) => {
      productControl.get("priceBrutto").valueChanges.subscribe((value) => {
        productControl.get("price").setValue(value * productControl.get("qty").value);
        this.calcSubTotal();
      });

      productControl.get("qty").valueChanges.subscribe((value) => {
        productControl.get("price").setValue(
          value * productControl.get("priceBrutto").value
        );
        this.calcSubTotal();
      });
    });
  }

  async getUserdataFromToken( token ) {
    let base64Url = token.split( '.' )[ 1 ];
    let base64 = base64Url.replace( '-', '+' ).replace( '_', '/' );
    return JSON.parse( atob( base64 ) );
  }

  async onSubmit(event): Promise<void> {
    event.preventDefault();
    this.form.markAllAsTouched();

    if (this.form.invalid || this.productsArray.value.length === 0 || this.hasSpecialOrder) return;
    if (!this.form.value.deliveryCost) this.form.get('deliveryCost').setValue(0);
    
    this.ngxService.startBackground("loader-03");
    this.disableButtons = true;
    
    await this.orderService.createSpeicalOrder(this.form.value, 0, "");
    
    this.ngxService.stopBackground();
    this.notification.showSnackbar('Der zentrale Einkauf wurde informiert. Bitte warten Sie auf Rückmeldung!', 'OK', 5500);
    this.router.navigate(['/einkauf']);
  }

  private async getUserId() {
    const id = await this.getUserdataFromToken( this.authService.token );
    return id.id;
  }

  public async onCancel() {
    if (this.hasSpecialOrder) return;

    const dialogRef = await this.dialog.open(ConfirmComponent, {
      width: '500px',
      data: {
        title: 'Bestellung',
        message: 'Wollen Sie die Sonderbestellung wirklich abbrechen?',
        buttons: {
          abort: 'Nein',
          submit: 'Ja',
        },
      },
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (!result) return;

      this.form.reset();
      this.productsArray.reset();
      this.router.navigate(['/einkauf']);
    })
  }
}
