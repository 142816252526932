import { Component, Inject } from '@angular/core';
import { UserService } from '../../services/user/user.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component( {
  selector: 'app-toc-dialog',
  templateUrl: './toc-dialog.component.html',
  styleUrls: [ './toc-dialog.component.scss' ]
} )
export class TocDialogComponent {

  constructor(
    private userService: UserService,
    public dialogRef: MatDialogRef<TocDialogComponent>,
    @Inject( MAT_DIALOG_DATA ) public data: any
  ) {
  }

  async accept() {
    try {
      let user = await this.userService.getUser( this.data.user );

      if ( !user.toc ) {
        user.toc = [];
      }

      user.toc.push( new Date() );

      await this.userService.editUser( user );
      this.dialogRef.close( true );
    } catch ( e ) {
      console.error( e );
    }
  }

  async decline() {
    try {
      await this.userService.editUser( {
        _id: this.data.user,
        refused: new Date(),
      } );
      this.dialogRef.close( false );
    } catch ( e ) {
      console.error( e );
    }
  }
}
