import { Action } from '@ngrx/store';

export const SET_CATEGORIES = '[Shop Product List] Set Categories';

export class SetCategories implements Action {
  readonly type = SET_CATEGORIES;

  constructor(public payload: Array<{}>) { }
}

export type ShopProductActions = SetCategories;

