import { NgModule } from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { SpecialOrderRoutingComponent } from './basket/special-order.component';
import { SpecialOrderRoutingModule } from './special-order-routing.module';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { SpecialOrderPipe } from './basket/special-order.pipe'

@NgModule({
  declarations: [ SpecialOrderRoutingComponent, SpecialOrderPipe ],
  imports: [
    CommonModule,
    CurrencyMaskModule,
    NgxUiLoaderModule,
    SpecialOrderRoutingModule,
    SharedModule,
    MatPaginatorModule
  ]
})
export class SpecialOrderModule { }
