import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Account, LOCATION } from '../../../types';
import { StandingOrderService } from '../../../services/standing-order/standing-order.service';
import { AccountService } from '../../../services/account/account.service';
import { LocationService } from '../../../services/location/location.service';
import { TagService } from '../../../services/tag/tag.service';
import { SpecialFactorService } from '../../../services/special-factor/special-factor.service';

const enum RHYTHM {
  single = 0,
  monthly = 1
}

const enum ENDTYPE {
  never = 0,
  date = 1,
  repetitions = 2
}

@Component( {
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: [ './create.component.scss' ],
} )
export class CreateComponent implements OnInit {
  @ViewChild('endTypeDatePicker') endTypeDatePicker: MatDatepicker<Date>;
  @ViewChild('startDatePicker') startDatePicker: MatDatepicker<Date>;

  public form: UntypedFormGroup;
  public apiError: boolean = false;
  public endTypeDatepickerDisabled: boolean = true;
  public minDate: Date;
  public locations: LOCATION[];
  public accounts: Account[];
  public factors: { _id: string, name: string, value: number }[];
  public tags: { name: string, _id: string, status: boolean }[];
  public valueTest;

  constructor(
    protected standingOrderService: StandingOrderService,
    protected accountService: AccountService,
    protected locationService: LocationService,
    protected tagService: TagService,
    protected specialFactorService: SpecialFactorService,
    protected formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CreateComponent>,
    @Inject( MAT_DIALOG_DATA ) public data: { location?: string },
  ) {
  }

  async ngOnInit() {
    this.form = this.formBuilder.group( {
      amount: [ 1, [ Validators.required, Validators.min( 1 ), Validators.pattern(/^[0-9]\d*$/) ] ],
      value: [ null, [ Validators.required, Validators.pattern(/^\d+(?:[,.]\d+)?$/) ] ],
      tag: [ '' ],
      location: [ { value: this.data.location || '', disabled: !!this.data.location }, [ Validators.required ] ],
      account: [ '', [ Validators.required ] ],
      startDate: [ { value: this.getNextFirstMonth(), disabled: false }, [ Validators.required ] ],
      endDate: [ { value: this.getNextFirstMonth(), disabled: true }, [ Validators.required ] ],

      has_factor: [ false ],
      factor: [ '0', [ Validators.required ] ],

      rhythm: [ null, [ Validators.required ] ],
      endTypeType: [ ENDTYPE.never ],
      endTypeDate: [ { value: '', disabled: true } ],
      endTypeRepetitions: [ { value: '', disabled: true } ],

      // helper
      fullValue: [ { value: '', disabled: true } ],
    } );    
    
    this.form.get( 'value' ).valueChanges.subscribe( val => {
      if(val && val.length > 1 && val.slice(-1) == ',' && val.indexOf(',') != val.length - 1) {
        this.form.get( 'value' ).setValue( val.substring(0, val.length - 1) );
      }
      this.updateFullValue();
    })
    
    this.form.get( 'amount' ).valueChanges.subscribe( val => {
      
      this.updateFullValue();
    })

    const startDate = new Date( this.form.get( 'startDate' ).value );
    this.minDate = startDate;
    this.updateFactor();

    this.form.get( 'endTypeType' ).valueChanges.subscribe( ( val: string ) => this.changeEndType( val ) );
    await this.loadData();
  }

  protected async loadData() {
    let [ locations, accounts, tags, factors ] = await Promise.all( [
      this.locationService.locationList(),
      this.accountService.list(),
      this.tagService.list(),
      this.specialFactorService.list(),
    ] );

    this.locations = locations.locations;
    this.accounts = accounts.accounts;
    this.tags = tags.tags;
    this.factors = factors.factors;
  }

  async submit() {
    this.apiError = false;

    if ( this.form.invalid ) {
      return;
    }
    let value =  this.formatNumber(this.form.get( 'value' ).value);
    value = parseFloat(value).toFixed(2);

    try {
      await this.standingOrderService.create( {
        amount: this.form.get( 'amount' ).value,
        value: Number(value),
        tag: [ this.form.get( 'tag' ).value ],
        location: [ this.form.get( 'location' ).value ],
        account: this.form.get( 'account' ).value,
        startDate: this.form.get( 'startDate' ).value,
        endDate: this.form.get( 'endDate' ).value,

        has_factor: this.form.get( 'has_factor' ).value,
        factor: this.form.get( 'factor' ).value,

        rhythm: parseInt( this.form.get( 'rhythm' ).value, 10 ),
        endType: this.generateEndType(),
      } );
      this.dialogRef.close( true );
    } catch ( e ) {
      this.apiError = true;
    }
  }

  public formatNumber(number:any) {
    // let result = number + "";
    // if(result && result != null && result.indexOf(',') !== -1) {
    //   let parts = result.toString().split(",");
    //   parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //   return parts.join("."); 
    // } else { 
    //   let arrayNumber = [];
    //   if(result) {
    //     arrayNumber.push(result.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","));
    //     arrayNumber.push("00");
    //   }
    //   return arrayNumber.join(".");
    // }
    return number;
  }

  protected generateEndType(): { type: number, value: Date | number } {
    let endType = {
      type: parseInt( this.form.get( 'endTypeType' ).value, 10 ),
      value: null,
    };

    switch ( endType.type ) {
      case ENDTYPE.date:
        endType.value = this.form.get( 'endTypeDate' ).value;
        break;
      case ENDTYPE.repetitions:
        endType.value = this.form.get( 'endTypeRepetitions' ).value;
        break;
      default:
        endType.value = '';
        break;
    }

    return endType;
  }

  protected getFullValue(): number {
    let value:any = parseFloat(this.formatNumber(this.form.get( 'value' ).value));
    return this.form.get( 'amount' ).value * value;
  }

  protected getNextFirstMonth(): Date {
    let now = new Date(),
      date = new Date( now.getFullYear(), now.getMonth(), now.getDate() );

    if ( date.getDate() !== 1 ) {
      date.setMonth( date.getMonth() + 1 );
      date.setDate( 1 );
    }

    return date;
  }

  protected changeEndType( value: string ): void {
    let type = parseInt( value, 10 );
    this.form.get( 'endTypeDate' ).disable();
    this.form.get( 'endTypeRepetitions' ).disable();
    this.form.get( 'endTypeDate' ).setValue( '' );
    this.form.get( 'endTypeRepetitions' ).setValue( '' );
    this.endTypeDatepickerDisabled = true;
    switch ( type ) {
      case ENDTYPE.date:
        this.endTypeDatepickerDisabled = false;
        break;
      case ENDTYPE.repetitions:
        this.form.get( 'endTypeRepetitions' ).enable();
        break;
    }
    this.updateEndDate();
  }

  protected updateEndDate() {
    const startDate = new Date( this.form.get( 'startDate' ).value );
    this.minDate = startDate;
    let endDate = new Date( startDate.getFullYear(), startDate.getMonth(), startDate.getDate() );

    if ( this.form.get( 'rhythm' ).value == RHYTHM.monthly ) {
      switch ( this.form.get( 'endTypeType' ).value ) {
        case '1':
          endDate = new Date( this.form.get( 'endTypeDate' ).value );
          this.endTypeDatepickerDisabled = false;
          break;
        case '2':
          endDate.setMonth( startDate.getMonth() + (parseInt( this.form.get( 'endTypeRepetitions' ).value, 10 ) - 1) );
          this.form.get( 'endTypeRepetitions' ).enable();
          break;
        default:
          endDate = new Date( 2500, 11, 1 );
      }
    }

    this.form.get( 'endDate' ).setValue( endDate );
  }

  changeHasFactor() {
    this.form.get( 'factor' ).setValue( '0' );
    this.form.get( 'value' ).setValue( 0 );
    this.form.get( 'amount' ).setValue( 1 );
  }

  updateFactor() {    
    let factor = this.form.get( 'factor' ).value;
    if ( factor === '0' ) {
      this.form.get( 'value' ).enable();
    } else {
      this.form.get( 'value' ).disable();
      for ( let factorItem of this.factors ) {
        if ( factorItem._id === factor ) {
          this.form.get( 'value' ).setValue( factorItem.value );
          break;
        }
      }
      this.form.get( 'fullValue' ).setValue( this.getFullValue() );
    }
  }

  updateRhythm() {
    this.form.get( 'endTypeType' ).setValue( '0' );
  }

  updateFullValue() {
    this.form.get( 'fullValue' ).setValue( this.getFullValue() );
  }

  monthSelected( date ) {
    this.form.get( 'endTypeDate' ).setValue( date );
    this.endTypeDatePicker.close();
  }
  monthStartSelected( date ) {
    this.form.get( 'startDate' ).setValue( date );
    this.updateEndDate();
    this.startDatePicker.close();
  }
}
