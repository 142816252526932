import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { SetCategories } from '../einkauf/shop-product-list/shop-product-list.action'
import * as fromRoot from './../app.reducer';

@Component({
  selector: 'app-datenschutz',
  templateUrl: './datenschutz.component.html',
  styleUrls: ['./datenschutz.component.scss']
})
export class DatenschutzComponent implements OnInit {

  constructor(private store: Store<fromRoot.State>) { }

  async ngOnInit() {
    this.store.dispatch(new SetCategories([{}]));
  }
}
