<div class="impressum">
  <h2 class="impressum-title">Impressum</h2>
  <div class="container">
    <div class="row">
      <div class="impressum-tile col-12 col-md-5 offset-md-1">
        <img src="../../assets/img/icons/paragraph.svg" alt="">
        <h4 class="impressum-tile-title">Gesellschaft</h4>
        <p class="impressum-tile-text">
          Business by Nature GmbH <br>
          Pretzfelderstr. 15 <br>
          90425 Nürnberg, Deutschland <br>
          Geschäftsführer: Swen Prokop, Michael Bergmann
        </p>
      </div>

      <div class="impressum-tile col-12 col-md-5">
        <img src="../../assets/img/icons/umsatzsteuerID.svg" alt="">
        <h4 class="impressum-tile-title">Umsatzsteuer-ID</h4>
        <p class="impressum-tile-text">
          Umsatzsteuer-Identifikationsnummer gemäß §27 a <br>
          Umsatzsteuergesetz: DE320927495
        </p>
      </div>

      <div class="impressum-tile col-12 col-md-5 offset-md-1">
        <img src="../../assets/img/icons/register.svg" alt="">
        <h4 class="impressum-tile-title">Registereintrag</h4>
        <p class="impressum-tile-text">
          Eintragung im Handelsregister <br>
          Registergericht: Amtsgericht Nürnberg<br>
          Registernummer: HRB 35733
        </p>
      </div>

      <div class="impressum-tile col-12 col-md-5">
        <img src="../../assets/img/icons/telefon.svg" alt="">
        <h4 class="impressum-tile-title">Kontakt</h4>
        <p class="impressum-tile-text">
          Telefon: +49 (0) 911 148 999 30 <br>
          E-Mail: info@mindsetsolution.de
        </p>
        <a href="mailto:info@mindsetsolution.de">E-Mail schreiben</a>
      </div>
    </div>
  </div>
</div>