import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from '../../shared/services/user/user.service';
import { EnvironmentService } from '../../environment.service';

@Component( {
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: [ './reset-password.component.scss' ],
} )
export class ResetPasswordComponent implements OnInit {
  public request: string;
  protected sub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe( async params => {
      this.request = params[ 'id' ];
      const user: { id: string, token: string, identifier: string } = this.fromHex( this.request );
      const newPassword = this.userService.genPass();

      try {
        await this.userService.passwordReset( user.id, user.token, newPassword );
        await this.sendCreationMail( user.identifier, newPassword );
        this.router.navigate( [ '/login' ] );
      } catch ( e ) {
        console.log( e );
      }

    } );
  }

  fromHex( param: string ) {
    let base64Url = param.split( '.' )[ 1 ];
    let base64 = base64Url.replace( '-', '+' ).replace( '_', '/' );
    return JSON.parse( atob( base64 ) );
  }

  async sendCreationMail( mail: string, password: string ) {  
    let SERVER = (new EnvironmentService()).SERVER;
    const data = {
      subject: 'Neues Passwort',
      body: `<h5 style="font-family: Arial, sans-serif;">Nachfolgend Ihr neues Passwort! <p style="font-family: Arial, sans-serif;">Passwort: ${ password }</p>
        <a href="${ SERVER.protocol }${ SERVER.host }/login" target="_blank" style="font-family: Arial, sans-serif;">Login</a></h5>`,
      trx: {
        rcpt: [ mail ],
        from: [ SERVER.email ],
      },
    };

    return await this.userService.sendMail( data );
  }

}
