<mat-card [class.special]="special" [class.mat-elevation-z3]="special">
  <mat-card-content>
    <p class="value">{{ account.total | currency:'EUR' }}</p>
    <p>
      <span class="icon-payment">
        <svg height="24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z"/></svg>
      </span>
      <span class="account-name">{{ account.name }}</span>
    </p>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button="mat-button" type="button" (click)="makeTransaction(true)" *ngIf="location?.deleted || ((user$ | async) !== userGroup.user && (user$ | async) !== userGroup.einkauf)">Einzahlung </button>
    <button mat-button="mat-button" type="button"  (click)="makeTransaction(false)" *ngIf="location?.deleted || ((user$ | async) !== userGroup.user && (user$ | async) !== userGroup.einkauf)">Abbuchung</button><a mat-button="mat-button" [routerLink]="getDetailsLink()">Details</a>
  </mat-card-actions>
</mat-card>
