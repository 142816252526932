import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsComponent } from './settings.component';
import { SettingsRoutingModule } from './settings-routing.module';
import { SharedModule } from '../shared/shared.module';
import { AclComponent } from './acl/acl.component';

@NgModule( {
  declarations: [
    SettingsComponent,
    AclComponent,
  ],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    SharedModule,
  ]
} )
export class SettingsModule {
}
