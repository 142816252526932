<!-- <div *ngIf="environment == 'einkauf'" [ngClass]="{'footer-wrap einkauf-footer': true, 'footer-position': (url == '/impressum' || url == '/datenschutz' || url == '/agb')}" [class.light]="!authService.authenticated && (url != '/impressum'  && url != '/datenschutz' && url != '/agb')"> -->
<div [ngClass]="{'footer-wrap einkauf-footer': true, 'footer-position': (url == '/impressum' || url == '/datenschutz' || url == '/agb'), 'footer-impressum' : url == '/impressum'}" [class.light]="!authService.authenticated && (url != '/impressum'  && url != '/datenschutz' && url != '/agb')">
  <div class="container">
    <div class="left">
      <p>
        <span class="last-deploy-date company-name">&copy; Copyright <b> Mindset Solution GmbH</b></span>
      </p>
      <nav>
        <ul>
          <li><a routerLink="/agb">AGB</a></li>
          <li><a routerLink="/datenschutz">Datenschutz</a></li>
          <li><a routerLink="/impressum">Impressum</a></li>
          <li *ngIf="!authService.authenticated"><a routerLink="/login">Login</a></li>
        </ul>
      </nav>
    </div>
    <div class="right">
      <p *ngIf="(userName !=null  || userGroupshow != null) && authService.authenticated">
        <span class="last-deploy-date">  Login: 
          <span class="username">{{userName }}</span>,  Version: <b>{{ version }}</b>
        </span>
      </p>
    </div>
  </div>
</div>

<!-- <div *ngIf="environment != 'einkauf'" [ngClass]="{'footer-wrap': true, 'footer-position': (url == '/impressum' || url == '/datenschutz' || url == '/agb')}" [class.light]="!authService.authenticated && (url != '/impressum' && url != '/datenschutz' && url != '/agb')">
  <div class="container clearfix">
    <div class="left">
      <p>
        <span class="last-deploy-date company-name">&copy; Copyright <b> Mindset Solution GmbH</b></span>
      </p>
      <nav>
        <ul>
          <li><a routerLink="/agb">AGB</a></li>
          <li><a routerLink="/datenschutz">Datenschutz</a></li>
          <li><a routerLink="/impressum">Impressum</a></li>
          <li *ngIf="!authService.authenticated"><a routerLink="/login">Login</a></li>
        </ul>
      </nav>
    </div>
    <div class="right">
      <p>
        <span *ngIf="(userName !=null  || userGroupshow != null) && authService.authenticated">
          Rolle: <em>{{userGroupshow }}</em>, Login:  <em>{{userName }} </em>, 
        </span> Version <b>{{ version }}</b> 
      </p>
    </div>
  </div>
</div> -->

