import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { AuthService } from "./core/auth/auth.service";
import { CartItemInformation, CartItemInformationLocation } from './shared/types';

@Injectable({
  providedIn: 'root'
})
export class RouteGuard implements CanActivate, CanActivateChild {
  cartInformations = new Subject<CartItemInformation>();
  cartInformationsLocation = new Subject<CartItemInformationLocation>();
  constructor(private authService: AuthService, private router: Router) {
  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {

    // let [ isAuthenticated, groups ] = await Promise.all( [
    //   this.authService.isAuthenticated(),
    //   this.authService.myGroups() ]
    // );

    // if ( isAuthenticated && groups.indexOf( 'a' ) || groups.indexOf( 'b' ) ) {
    if (await this.authService.isAuthenticated() || state.url == "/impressum") {
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }

  async canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return true;
  }
}
