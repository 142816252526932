import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { PasswordChangeComponent } from './shared/components/password-change/password-change.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { NotificationService } from './shared/services/notification/notification.service';
import { AuthService } from './core/auth/auth.service';
import { UserService } from './shared/services/user/user.service';
import { NavigationEnd, Router } from '@angular/router';
import { StorageService } from './core/storage/storage.service';
import { User } from './shared/types';
import { TocDialogComponent } from './shared/components/toc-dialog/toc-dialog.component';
import { CartService } from './shared/services/cart/cart.service';
import { Subscription, Observable } from 'rxjs';
import { RouteGuard } from './route.guard';
import { Store } from '@ngrx/store';
import * as fromRoot from './app.reducer';
import { UserGroup } from './authentication/authentication.reducer';
import { SetUserGroup } from './authentication/authentication.action';
import { version } from '../../package.json';
import { EnvironmentService } from './environment.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav') sidenav: MatSidenav;
  public user: User = null;
  public totalBasketPrice = 0;
  public totalBasketPriceLocation = 0;
  public totalProductsInBasket = 0;
  public totalProductsInBasketLocation = 0;
  private subs: Subscription;
  public userName;
  public userGroupshow;
  public environment;
  public url;
  public urlParams;
  public isBasketDisabled: boolean = false;

  public user$: Observable<UserGroup | string>;
  public userGroup = UserGroup;

  constructor(
    private routeGuard: RouteGuard,
    private cartService: CartService,
    private dialog: MatDialog,
    public authService: AuthService,
    private userService: UserService,
    private router: Router,
    private storage: StorageService,
    private notification: NotificationService,
    private store: Store<fromRoot.State>
  ) {
    this.subs = this.routeGuard.cartInformations.subscribe(res => {
      this.totalProductsInBasket = res.amountProductsInCart;
      this.totalBasketPrice = res.totalPrice;
    });

    this.subs = this.routeGuard.cartInformationsLocation.subscribe(res => {
      this.totalProductsInBasketLocation = res.amountProductsInCartLocation;
      this.totalBasketPriceLocation = res.totalPriceLocation;
    });
  }

  async ngOnInit(): Promise<void> {
    this.router.events.subscribe(async (e) => {
      if (e instanceof NavigationEnd) {
        this.url = e.url;
        this.urlParams = e.url.split("/");
      }

      if (e instanceof NavigationEnd && await this.authService.isAuthenticated()) {
        let opened = await this.checkForTermsAndConditionsDialog();
        if (!opened) {
          this.checkForPasswordDialog();
        }
      }
    });
    this.environment = (new EnvironmentService()).SERVER.name;

    if (await this.authService.isAuthenticated()) {
      try {
        const user = await this.getUser();
        const userGroup = user.groups[0];

        this.store.dispatch(new SetUserGroup(userGroup));
        this.user$ = this.store.select(fromRoot.getUserGroup);
      } catch (e) {
        switch (e && e.statusCode) {
          case 404:
            this.authService.logout();
            this.router.navigate(['/login']);
          default:
            this.authService.logout();
            this.router.navigate(['/login']);
        }
      }
    } else return;
    window.setInterval(await this.checkInactivity.bind(this), 60000, "Inactivity Check");

    const userId = await this.getUserId();
    const cartId = await this.cartService.getCartId(userId);
    const res = await this.cartService.list(cartId);
    if (Array.isArray(res) && res.length === 0) return;

    if ((Array.isArray(res) && res.length !== 0 && res[0].flag === 5) || (res.cartItem.length !== 0 && res.cartItem[0].flag === 5)) {
      this.isBasketDisabled = true;
    }
  }

  public async checkInactivity() {
    let inActive = await this.authService.isAuthenticated();

    if (!inActive) {
      this.logout()
      this.router.navigate(['/login']);
    }
  }

  private async checkForTermsAndConditionsDialog() {
    let tocAccepted = true;

    try {
      tocAccepted = await this.storage.get('toc_accepted');
    } catch (e) {
      const user = await this.getUser();
      tocAccepted = typeof user.toc !== 'undefined';

      await this.storage.set('toc_accepted', tocAccepted);
    }

    if (!tocAccepted) {
      await this.openTermsAndCOnditionsDialog();
      return true;
    } else {
      const userId = await this.getUserId();
      const cartId = await this.cartService.getCartId(userId);
      await this.storage.set('cart_id', cartId);
      let basket: any;
      this.cartService.list(cartId).then(res => basket = res).catch(e => console.log(e));

      if (basket && basket.productsDeleted && basket.productsDeleted.length > 1) {
        if (basket.productsDeleted[1].hasOwnProperty('name')) {
          let products = "";
          for (let n = 1; n < basket.productsDeleted.length; n++) {
            products = products + " '" + basket.productsDeleted[n].name + "'";
          }
          setTimeout(async () => {
            this.notification.showSnackbar('Produkt ' + products.toUpperCase() + ' wurde aus dem Warenkorb entfernt, da der Lieferant oder das Produkt nicht mehr verfügbar ist!', 'OK', 5500);
          }, 1000);
        }
      }
    }

    return false;
  }

  private async checkForPasswordDialog() {
    let genPassword = true;

    try {
      genPassword = await this.storage.get('gen_password');
    } catch (e) {
      const user = await this.getUser();
      await this.storage.set('gen_password', !!user.gen_password);
      genPassword = !!user.gen_password;
    }

    if (genPassword) {
      await this.openPasswordDialog();
    }
  }

  private async openTermsAndCOnditionsDialog() {
    let dialogRef = await this.dialog.open(TocDialogComponent, {
      data: { user: await this.getUserId() },
      width: '600px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {

        await this.authService.logout();
        this.router.navigate(['/']);
        await this.storage.remove('user_identifier');
        await this.storage.remove('user_group');
        await this.storage.set('toc_accepted', false);
      } else {
        await this.storage.set('toc_accepted', true);
        this.checkForPasswordDialog();
      }
    });
  }

  private async openPasswordDialog() {
    const dialogRef = await this.dialog.open(PasswordChangeComponent, {
      data: { user: await this.getUserId(), disableClose: true },
      width: '400px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        await this.storage.set('gen_password', false);
      }
    });
  }

  private async getUser() {
    let userId = await this.getUserId();
    this.user = await this.userService.getUser(userId);
    return this.user;
  }

  private async getUserId() {
    const id = await this.getUserdataFromToken(this.authService.token);
    return id?.id;
  }

  async getUserdataFromToken(token) {
    if (token) {
      let base64Url = token.split('.')[1];
      let base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse(atob(base64));
    }
  }

  async logout() {
    this.authService.logout();
    //DP
    this.authService.refreshUserIdentifierAndGroup(this.userName, this.userGroupshow);
    this.router.navigate(['/login']);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
