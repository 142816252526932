import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/api/api.service';
import { CartItemInformation, CartItemInformationLocation, ProductIntoCartItem } from '../../types';
import { Subject } from 'rxjs';
import { RouteGuard } from 'src/app/route.guard';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  // cartInformations = new Subject<CartItemInformation>();

  constructor(protected api: ApiService, private routeGuard: RouteGuard) {
  }

  protected async _call(action: string, data: any = {}) {
    return await this.api.rpc(`shop/cart/${action}`, data);
  }

  async create(productIntoCart: ProductIntoCartItem) {
    return this._call('create', productIntoCart);
  }
  async getUsers(id: string) {
    return await this.api.rpc('crm/locations/getUsers', { _id: id });
  }
  async supplierlocationList(search?: string) {
    return await this.api.rpc('crm/locations/list', { search });
  }

  async update(editCartItem: { shop_cart_item_id: string, qty: number }) {
    return await this._call('edit', editCartItem);
  }

  async recreate(cart_id, user_id, continueOrder:boolean = false) {
    let result = await this._call('recreate', { cart_id, continueOrder });
    let id = await this.getCartId(user_id);
    await this.list(id)
    return result;
  }

  async getCartId(user_id: string) {
    let cartId = '';
    try {
      const cartIdItem = await this._call('get', { user_id });
      cartId = cartIdItem._id;
    } catch (error) {
      return cartId;
    }
    return cartId;
  }

  async list(cart_id: string = null) {
    if (cart_id === '') {
      // reset cartItemInformation if no cart_id
      this.routeGuard.cartInformations.next({
        totalPrice: 0,
        amountProductsInCart: 0
      });
      return [];
    }
    const products = await this._call('list', { cart_id });
    let calculatedTotalPrice = 0;
    let amountsOfProductsInCart = 0;
    products.cartItem.forEach(element => {
      if (element.flag === 5) {
          calculatedTotalPrice += (element.qty * element.price);
          amountsOfProductsInCart += element.qty;
      } 
      else {
        calculatedTotalPrice += (element.qty * element.product[0].price_brutto);
        amountsOfProductsInCart += element.qty;
      }
    });

    const cartItemInformation = {
      totalPrice: calculatedTotalPrice,
      amountProductsInCart: amountsOfProductsInCart
    };

    this.routeGuard.cartInformations.next(cartItemInformation);

    return products;
  }

  async listByLocation(location_id: string = '', cart_id:string = '', supplier_id:string = '') {
    if (location_id === '') {
      // reset cartItemInformation if no cart_id
      this.routeGuard.cartInformationsLocation.next({
        totalPriceLocation: 0,
        amountProductsInCartLocation: 0
      });
      return [];
    }
    const products = await this._call('listByLocation', { location_id, cart_id, supplier_id });
    let calculatedTotalPriceLocation = 0;
    let amountsOfProductsInCartLocation = 0;

    products.cartItem.forEach(element => {
      calculatedTotalPriceLocation += (element.qty * element.price);
      amountsOfProductsInCartLocation += element.qty;
      // totalPricel += calculatedTotalPrice;
    });
    const cartItemInformationLocation = {
      totalPriceLocation: calculatedTotalPriceLocation,
      amountProductsInCartLocation: amountsOfProductsInCartLocation
    };
    this.routeGuard.cartInformationsLocation.next(cartItemInformationLocation);
    return products;
  }

  async getByLocationAndSupplier(cart_id: string = '') {
    if (cart_id === '') {
      // reset cartItemInformation if no cart_id
      this.routeGuard.cartInformationsLocation.next({
        totalPriceLocation: 0,
        amountProductsInCartLocation: 0
      });
      return [];
    }
    const products = await this._call('getByLocationAndSupplier', { cart_id });
    let calculatedTotalPriceLocation = 0;
    let amountsOfProductsInCartLocation = 0;


    products.orders.forEach(element => {
      calculatedTotalPriceLocation += (element.qty * element.price);
      amountsOfProductsInCartLocation += element.qty;
      // totalPricel += calculatedTotalPrice;
    });
    const cartItemInformationLocation = {
      totalPriceLocation: calculatedTotalPriceLocation,
      amountProductsInCartLocation: amountsOfProductsInCartLocation
    };
    this.routeGuard.cartInformationsLocation.next(cartItemInformationLocation);
    return products;
  }
  async getBySupplier(cart_id: string = '') {
    if (cart_id === '') {
      // reset cartItemInformation if no cart_id
      this.routeGuard.cartInformationsLocation.next({
        totalPriceLocation: 0,
        amountProductsInCartLocation: 0
      });
      return [];
    }
    const products = await this._call('getBySupplier', { cart_id });
    let calculatedTotalPriceLocation = 0;
    let amountsOfProductsInCartLocation = 0;


    products.orders.forEach(element => {
      calculatedTotalPriceLocation += (element.qty * element.price);
      amountsOfProductsInCartLocation += element.qty;
      // totalPricel += calculatedTotalPrice;
    });
    const cartItemInformationLocation = {
      totalPriceLocation: calculatedTotalPriceLocation,
      amountProductsInCartLocation: amountsOfProductsInCartLocation
    };
    this.routeGuard.cartInformationsLocation.next(cartItemInformationLocation);
    return products;
  }

  async listByLocationAndSupplier(location_id: string = '') {
    if (location_id === '') {
      // reset cartItemInformation if no cart_id
      this.routeGuard.cartInformationsLocation.next({
        totalPriceLocation: 0,
        amountProductsInCartLocation: 0
      });
      return [];
    }
    const products = await this._call('listByLocationAndSupplier', { location_id });
    // let calculatedTotalPriceLocation = 0;
    // let amountsOfProductsInCartLocation = 0;


    // products.cartItem.forEach(element => {
    //   calculatedTotalPriceLocation += (element.qty * element.product[0].price_brutto);
    //   amountsOfProductsInCartLocation += element.qty;
    //   // totalPricel += calculatedTotalPrice;
    // });
    // const cartItemInformationLocation = {
    //   totalPriceLocation: calculatedTotalPriceLocation,
    //   amountProductsInCartLocation: amountsOfProductsInCartLocation
    // };
    // this.routeGuard.cartInformationsLocation.next(cartItemInformationLocation);
    return products;
  }
  //Donart
  async listBySupplier(supplier_id: string = '') {
    if (supplier_id === '') {
      // reset cartItemInformation if no cart_id
      this.routeGuard.cartInformationsLocation.next({
        totalPriceLocation: 0,
        amountProductsInCartLocation: 0
      });
      return [];
    }
    const products = await this._call('listBySupplier', { supplier_id });
    return products;
  }
  async listByAdmin() {
    const cartOrder = await this._call('listByAdmin', {});
    return cartOrder;
  }

  async delete(_id: string) {
    return await this._call('remove', { _id });
  }

  async cancelCart(cart_id: string) {
    this._call('remove', { cart_id }).then(() => {
      const cartItemInformation = {
        totalPrice: 0,
        amountProductsInCart: 0
      };
      this.routeGuard.cartInformations.next(cartItemInformation);
    });
  }

  async cancelLocationCart(cart_id: string) {
    this._call('remove', { cart_id }).then(() => {
      const cartItemInformation = {
        totalPriceLocation: 0,
        amountProductsInCartLocation: 0
      };
      this.routeGuard.cartInformationsLocation.next(cartItemInformation);
    });
  }
}
