import { Component, getModuleFactory, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth/auth.service';
import { environment } from './../../../environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { StorageService } from './../../core/storage/storage.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { EnvironmentService } from '../../environment.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentYear: number;
  deployDate: any;
  public userName;
  public userGroupshow;
  public environment;
  public url;
  public version;

  constructor(
    public authService: AuthService,
    private router: Router,
    private storageService: StorageService,
    private userService: UserService
  ) { }

  async ngOnInit() {
    // this might break...
    //let { environment } = await import("./../../../environments/environment." + SERVER.name);

    this.authService.userIdAndGroupObserver.subscribe(user => {
      this.userName = user.user_identifier;
      this.userGroupshow = user.user_group;
    });
    
    this.router.events.subscribe(async (e) => {
      if (e instanceof NavigationEnd)
        this.url = e.url;
    });
    this.url = this.router.url;

    this.currentYear = new Date().getFullYear();
    this.deployDate = environment.buildTimestamp;
    this.environment = (new EnvironmentService()).SERVER.name;
    this.version = environment.version;

    try {
      this.userName = await this.storageService.get("user_identifier");

      this.userGroupshow = await this.storageService.get("user_group");

      if (this.userGroupshow == null || this.userGroupshow == undefined) {
        this.userGroupshow = "";
      }
      if (this.userName == null || this.userName == undefined) {
        this.userName = "";
      }

      if (this.userGroupshow == "super_admin") {
        this.userGroupshow = "Super Admin";
      }
      else if(this.userGroupshow =="admin"){
        this.userGroupshow="Admin";
      }
      else if(this.userGroupshow =="einkauf"){
        this.userGroupshow="Einkauf";
      }
      else if (this.userGroupshow == "user") {
        this.userGroupshow = "User";
      }
      else if (this.userGroupshow == "lieferant") {
        this.userGroupshow = "Lieferant";
      }
    } catch (e) {}
  }
}
