import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SpecialOrderRoutingComponent } from './basket/special-order.component';
import { RouteGuard } from "../route.guard";

const routes: Routes = [ {
  path: 'sonderbestellung',
  canActivate: [RouteGuard],
  component: SpecialOrderRoutingComponent,
} ];

@NgModule( {
  imports: [ RouterModule.forChild( routes ) ],
  exports: [ RouterModule ]
} )
export class SpecialOrderRoutingModule {}