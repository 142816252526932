import { Injectable } from '@angular/core';
import { ApiService } from "../../../core/api/api.service";

@Injectable({
  providedIn: 'root'
})
export class AclService {

  constructor( protected api: ApiService) { }

  async setAllow(group: string, path: string) {
    return await this.api.rpc('crm/acl/allow', {ressource: path, role: group});
  }

  async setDeny(group: string, path: string) {
    return await this.api.rpc('crm/acl/deny', {ressource: path, role: group});
  }
}
