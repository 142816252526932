import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/api/api.service';
import { OrderItem, ProductIntoCartItem, CartItemInformationLocation } from '../../types';
import { RouteGuard } from 'src/app/route.guard';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(protected api: ApiService, private routeGuard: RouteGuard) {
  }

  protected async _call(action: string, data: any = {}) {
    return await this.api.rpc(`shop/order/${action}`, data);
  }

  async create(productIntoCart) {
    return this._call('create', productIntoCart);
  }

  async edit(cart_id: string, supplier_id:string = '', editByEinkauf: boolean) {
    return this._call('edit', {cart_id, supplier_id, editByEinkauf});
  }

  async list(user_id: string) {
    return this._call('list', { user_id });
  }

  async listForEinkauf(user_id: string, page_size, page_index, search_number, search_location, filter_date, search_price, onlyCompletedOrder, search_supplier) {
    return this._call('listForEinkauf', { user_id, page_size, page_index, search_number, search_location, filter_date, search_price, onlyCompletedOrder, search_supplier });
  }

  async get(order_id: string) {
    if (order_id === '') {
      // reset cartItemInformation if no cart_id
      this.routeGuard.cartInformationsLocation.next({
        totalPriceLocation: 0,
        amountProductsInCartLocation: 0
      });
      return [];
    }
    const products = await this._call('get', { order_id });
    let calculatedTotalPriceLocation = 0;
    let amountProductsInCartLocation = 0;

    products.orders.forEach(element => {
      amountProductsInCartLocation += element.qty;
      if (element.flag === 5) calculatedTotalPriceLocation += element.qty * element.special_order_details.price_brutto;
      else calculatedTotalPriceLocation += (element.qty * element.price);
    });

    const cartItemInformationLocation = {
      totalPriceLocation: calculatedTotalPriceLocation,
      amountProductsInCartLocation: amountProductsInCartLocation
    };
    this.routeGuard.cartInformationsLocation.next(cartItemInformationLocation);

    return products;
  }

  async remove(cart_id: string, supplier_id:string = '', editByEinkauf: boolean) {
    return this._call('remove', {cart_id, supplier_id, editByEinkauf}).then( (res) => {
      const CartItemInformationLocation = {
        totalPriceLocation: 0,
        amountProductsInCartLocation: 0
      };
      this.routeGuard.cartInformationsLocation.next(CartItemInformationLocation);
      return res;
    });;
  }
  
/**
 * @param flag 0 -> create order, 1 -> accept order, 2 -> reject order.
 */
  async createSpeicalOrder(cart: any, flag: number, cart_id: string, removedProductsIds?: string[]) {
    return this._call('create-special-order', {...cart, cart_id, flag, removedProductsIds});
  }
}
