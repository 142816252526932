import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/api/api.service';
import { Category } from '../../types';

@Injectable( {
  providedIn: 'root',
} )
export class ShopCategoryService {

  constructor( protected api: ApiService ) {
  }

  protected async _call( path: string, data: any = {} ) {
    return await this.api.rpc( `shop/${ path }`, data );
  }

  async delete( _id: string ) {
    return await this._call( 'category/remove', { _id } );
  }

  async list( filter = '' ) {
    return await this._call( 'category/list', { filter } );
  }

  async create( category: Category ) {
    return await this._call( 'category/create', category );
  }

  async update( category: Category ) {
    return await this._call( 'category/edit', category );
  }

  async get( _id: string ) {
    return await this._call( 'category/get', { _id } );
  }

  async listTree(categoriesWithoutProducts: boolean = true, categoriesWithoutProductsTree: boolean = false, supplier_id: string = '') {
    return await this._call( 'category/listTree', { categoriesWithoutProducts, categoriesWithoutProductsTree, supplier_id } );
  }

  async cache() {
    return await this._call('category/cache', {});
  }
}
