
<div class="login-wrap">
  <div class="container__row">
    <div class="col-4 col-sm-4 col-md-offset-2 col-md-4 col-lg-offset-4 col-lg-4">
      <mat-card class="centered-title">
        <mat-card-header>
          <mat-card-title>Bitte warten</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>Sie sollten in wenigen Sekunden weitergeleitet werden.</p>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>