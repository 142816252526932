import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccountService } from '../../services/account/account.service';
import { Account, Transaction } from '../../types';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TransactionService } from "../../services/transaction/transaction.service";

@Component({
  selector: 'app-account-deposit',
  templateUrl: './account-deposit.component.html',
  styleUrls: ['./account-deposit.component.scss']
})
export class AccountDepositComponent implements OnInit {
  public subAccounts: Account[] = [];
  public form: UntypedFormGroup;
  public apiError: boolean = false;
  public minDate: Date;
  today: number = Date.now();

  constructor(

    protected accountService: AccountService,
    protected formBuilder: UntypedFormBuilder,
    protected transactionService: TransactionService,
    public dialogRef: MatDialogRef<AccountDepositComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { location: string, subAccount?: string },

  ) {}

  async ngOnInit() {
    var currentDate = new Date();
    currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    this.minDate=currentDate;
    this.form = this.formBuilder.group({
      subAccount: [this.data.subAccount, [Validators.required]],
      value: [null, [Validators.required, Validators.min(0)]],
      description: ['', [Validators.required]],
      date: [new Date().toISOString(), [Validators.required]],
    });

    if (this.data.subAccount) {
      this.form.controls.subAccount.disable();
    }
    let accountsResult = await this.accountService.list();
    this.subAccounts = accountsResult.accounts;
  }

  async create() {
    this.apiError = false;
    if (this.form.invalid) {
      return;
    }

    try {
      let transaction: Transaction = {
        location: this.data.location,
        account: this.form.controls.subAccount.value,
        value: this.form.controls.value.value,
        description: this.form.controls.description.value,
        date: this.form.controls.date.value,
        status: this.form.controls.date.value > new Date() ? 1 : 0,
        type: true,
      };

      await this.transactionService.create(transaction);
      this.dialogRef.close(true);
    } catch (e) {
      this.apiError = true;
    }
  }
}
