import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private config: MatSnackBarConfig;

  constructor(
    private snackbar: MatSnackBar

  ) {
    this.config = new MatSnackBarConfig()
    this.config.duration = 2000
   }

  public showSnackbar(message: string, action= null, duration=null ) {
    if(duration) {
      this.config.duration = duration;
    }
    return this.snackbar.open(message, action, this.config);
  }
}
