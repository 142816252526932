import { ShopProductActions, SET_CATEGORIES } from './shop-product-list.action';

export interface State {
  categories: Array<{}>;
}

const initialState: State = {
  categories: [{}]
};

export function shopListReducer(state = initialState, action: ShopProductActions) {
  switch (action.type) {
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      };
    default:
      return state;
  }
}

export const getCategoryList = (state: State) => state.categories;
