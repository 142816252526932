import { NotificationService } from './../../shared/services/notification/notification.service';
import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { UserService } from "../../shared/services/user/user.service";

@Component( {
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: [ './reset.component.scss' ]
} )
export class ResetComponent implements OnInit {
  public mailField: UntypedFormControl;
  public form: UntypedFormGroup;

  constructor(
    private notification: NotificationService,
    private router: Router,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    this.mailField = new UntypedFormControl('', [
      Validators.required,
      Validators.email,
    ]);
  }

  async requestPassword() {
    if(this.mailField.invalid) {
      return false;
    }

    // TODO: send to backend
    // this.mailField.value
    try {
      await this.userService.requestReset(this.mailField.value.toLowerCase());
      await this.notification.showSnackbar( 'Wir schicken eine Mail an die angegebene E-Mail-Adresse', 'OK' );

      this.router.navigate( [ '/login' ] );
    } catch ( e ) {
      const snackRef = await this.notification.showSnackbar( 'Die angegebene E-Mail-Adresse kann nicht gefunden werden', 'OK' );
      snackRef.afterDismissed().subscribe(result => {
        this.mailField.setValue( '');
      });
    }
  }
}
