import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard.component';
import { RouteGuard } from "../route.guard";

const routes: Routes = [ {
  path: 'dashboard',
  canActivate: [RouteGuard],
  component: DashboardComponent,
} ];

@NgModule( {
  imports: [ RouterModule.forChild( routes ) ],
  exports: [ RouterModule ]
} )
export class DashboardRoutingModule {
}
