import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component( {
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: [ './terms-and-conditions.component.scss' ]
} )
export class TermsAndConditionsComponent {
  constructor(
    public dialogRef: MatDialogRef<TermsAndConditionsComponent>,
    @Inject( MAT_DIALOG_DATA ) public data: { htmlTemplate?: string, pdfSource?: string, title?: string }
  ) {
  }
  handlePdfShowError(event: any) {
    if (this.data.pdfSource) 
      window.open(this.data.pdfSource, '_blank')

    this.dialogRef.close();
  }
}
