<mat-card-header [class.disabled-location]="currentLocation ? !currentLocation?.status : false">
  <mat-card-title>Daueraufträge <ng-container class="mat-small" *ngIf="currentLocation ? !currentLocation?.status : false">&nbsp;[INAKTIV]</ng-container></mat-card-title>
</mat-card-header>
<mat-card-content [class.disabled-location]="currentLocation ? !currentLocation?.status : false">
  <p class="account-value" *ngIf="location">{{ standingOrdersTotal | currency:'EUR' }}</p>
  <p class="account-sub-value" *ngIf="location">Buchungen zum {{ showDate | date }}</p>
  <button class="add" mat-raised-button="mat-raised-button" (click)="createStandingOrder()" [disabled]="currentLocation ? (!currentLocation?.status || (user$ | async) !== userGroup.admin_einkauf && (user$ | async) !== userGroup.super_admin && (user$ | async) !== userGroup.admin) : ((user$ | async) !== userGroup.super_admin && (user$ | async) !== userGroup.admin) && (user$ | async) !== userGroup.admin_einkauf">
    <span class="icon-note-add">
      <svg  height="24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 14h-3v3h-2v-3H8v-2h3v-3h2v3h3v2zm-3-7V3.5L18.5 9H13z"/></svg>
    </span>
    <span>Neuer Dauerauftrag</span>
  </button>
  <mat-accordion>
    <mat-expansion-panel *ngFor="let standingOrder of standingOrders">
      <mat-expansion-panel-header>
        <mat-panel-title>{{ (standingOrder.value * standingOrder.amount) | currency:'EUR' }}</mat-panel-title>
        <mat-panel-description *ngIf="standingOrder.tag.length">
          <mat-chip-list>
            <mat-chip color="primary" selected="selected" [class.disabled-tag]="!tagsStatus [standingOrder.tag[ 0 ]]">
              {{ tags[ standingOrder.tag[ 0 ] ] }}<ng-container class="mat-small" *ngIf="!tagsStatus [standingOrder.tag[ 0 ]]">&nbsp;[INAKTIV]</ng-container>
            </mat-chip>
          </mat-chip-list>
          <div class="subaccount-name"><strong>Konto: </strong>{{ accounts [ standingOrder.account ] }}</div>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <table>
        <tr>
          <td><strong>Startdatum</strong></td>
          <td>{{ standingOrder.startDate | date }}</td>
          <td><strong>Standort</strong></td>
          <td>{{ locations[ standingOrder.location ] }}</td>
        </tr>
        <tr>
          <td><strong>Enddatum</strong></td>
          <td>{{ standingOrder.endDate | date }}</td>
          <td><strong>Konto</strong></td>
          <td>{{ accounts [ standingOrder.account ] }}</td>
        </tr>
        <tr> 
          <td><strong>Wiederholungsrate</strong></td>
          <td>{{ standingOrder.rhythm == 0 ? 'Einmalig' : 'Monatlich' }}</td>
          <td><strong *ngIf="standingOrder.specialFaktor">Spezialfaktor</strong></td>
          <td><span *ngIf="standingOrder.specialFaktor">{{ standingOrder.specialFaktor }}</span></td>
        </tr>
      </table>
      <mat-action-row align="end">
        <button mat-button="mat-button" (click)="deleteStandingOrder(standingOrder._id)" [disabled]="(user$ | async) !== userGroup.super_admin && (user$ | async) !== userGroup.admin && (user$ | async) !== userGroup.admin_einkauf">Löschen</button>
        <button mat-raised-button="mat-raised-button" color="primary" (click)="editStandingOrder(standingOrder._id)" [disabled]="(user$ | async) !== userGroup.super_admin && (user$ | async) !== userGroup.admin && (user$ | async) !== userGroup.admin_einkauf">Bearbeiten</button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>
</mat-card-content>
