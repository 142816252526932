import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/api/api.service';
import { Account } from '../../types';

@Injectable( {
  providedIn: 'root'
} )
export class AccountService {

  constructor(
    protected api: ApiService
  ) {
  }

  protected async _call( action: string, data: any = {} ) {
    return await this.api.rpc( `crm/account/${ action }`, data );
  }

  async addDelivery( id ) {
    return await this._call( 'addDelivery', {_id: id });
  }
  async create( account: Account ) {
    return await this._call( 'create', account );
  }

  async edit( account: Account ) {
    return await this._call( 'edit', account );
  }

  async list(): Promise<{accounts: Account[]}> {
    return await this._call( 'list' );
  }

  async remove( id, deleted ) {
    return await this._call( 'remove', { _id: id, deleted: deleted } );
  }
}
