import * as fromAuth from './authentication/authentication.reducer';
import * as fromShopList from './einkauf/shop-product-list/shop-product-list.reducer';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

export interface State {
  auth: fromAuth.State;
  categories: fromShopList.State
}

export const reducers: ActionReducerMap<State> = {
  auth: fromAuth.authReducer,
  categories: fromShopList.shopListReducer
}

export const getAuthState = createFeatureSelector<fromAuth.State>('auth');
export const getCategoriesState = createFeatureSelector<fromShopList.State>('categories');
export const getIsAuth = createSelector(getAuthState, fromAuth.getIsAuth);
export const getUserGroup = createSelector(getAuthState, fromAuth.getUserGroup);

export const getCategoryList = createSelector(getCategoriesState, fromShopList.getCategoryList);
