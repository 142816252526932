import { Injectable } from '@angular/core';

export interface IStorage {
  get( key: string ): Promise<any>
  set( key: string, value: any ): Promise<boolean>
  remove( key: string ): Promise<boolean>
}

@Injectable()
export class StorageService implements IStorage {

  get( key ) {
    try {

      let value = localStorage.getItem( key );

      if ( null === value ) {
        return Promise.reject( null );
      }

      return Promise.resolve( JSON.parse( value ) );

    } catch ( e ) { return Promise.reject( e ); }
  }

  set( key, value ) {
    try {

      localStorage.setItem( key, JSON.stringify( value ) );
      return Promise.resolve( true );

    } catch ( e ) { return Promise.reject( e ); }
  }

  remove( key ) {
    try {

      localStorage.removeItem( key );
      return Promise.resolve( true );

    } catch ( e ) { return Promise.reject( e ) }
  }

  getSession( key ) {
    try {

      let value = sessionStorage.getItem( key );

      if ( null === value ) {
        return Promise.reject( null );
      }

      return Promise.resolve( JSON.parse( value ) );

    } catch ( e ) { return Promise.reject( e ); }
  }

  setSession( key ) {
    try {

      sessionStorage.setItem( "expireDate", key );
      return Promise.resolve( true );

    } catch ( e ) { return Promise.reject( e ) }
  }

  removeSession( key ) {
    try {

      sessionStorage.removeItem( key );
      return Promise.resolve( true );

    } catch ( e ) { return Promise.reject( e ) }
  }
}
