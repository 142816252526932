
<form (submit)="submit()" [formGroup]="form">
  <h1 mat-dialog-title="mat-dialog-title">Dauerauftrag bearbeiten</h1>
  <div mat-dialog-content="mat-dialog-content">
    <mat-error *ngIf="apiError">Es ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.</mat-error>
    <div>
      <mat-slide-toggle formControlName="has_factor" color="primary" (ngModelChange)="changeHasFactor()">mit Faktor</mat-slide-toggle>
    </div>
    <mat-card *ngIf="form.get(&quot;has_factor&quot;).value">
      <mat-form-field>
        <mat-select formControlName="factor" placeholder="Spezialfaktor" (ngModelChange)="updateFactor()">
          <mat-option value="0">Kein Spezialfaktor</mat-option>
          <mat-option *ngFor="let factor of factors" value="{{ factor._id }}">{{ factor.name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="form.hasError(&quot;required&quot;, &quot;factor&quot;)">Es muss ein Spezialfaktor ausgewählt werden.</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input 
          matInput="matInput" 
          type="number" 
          placeholder="Anzahl Kinder" 
          formControlName="amount"
          onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
          (change)="updateFullValue()"/>
        <mat-error *ngIf="form.hasError(&quot;required&quot;, &quot;amount&quot;)">Die Anzahl der Kinder muss angegeben werden.</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput="matInput" type="string" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 2 }" placeholder="Faktor" formControlName="value" (ngModelChange)="updateFullValue()"/>
        <mat-error *ngIf="form.hasError(&quot;required&quot;, &quot;value&quot;)">Der Faktor muss angegeben werden.</mat-error><span matSuffix="matSuffix">€</span>
      </mat-form-field>
    </mat-card>
    <mat-form-field>
      <input matInput="matInput" type="string" placeholder="Gesamtbetrag" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 2 }" formControlName="fullValue"/><span matSuffix="matSuffix">€</span>
    </mat-form-field>
    <div *ngIf="!form.get(&quot;has_factor&quot;).value">
      <mat-form-field>
        <input matInput="matInput" type="string" placeholder="Wert" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 2 }" formControlName="value"/>
        <mat-error *ngIf="form.hasError(&quot;required&quot;, &quot;value&quot;)">Der Wert muss angegeben werden.</mat-error><span matSuffix="matSuffix">€</span>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-select formControlName="tag" placeholder="Bezeichnung">
          <mat-option *ngFor="let tag of tags" value="{{ tag._id }}">{{ tag.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="locations">
      <mat-form-field>
        <mat-select formControlName="location" placeholder="Standort">
          <mat-option *ngFor="let location of locations" value="{{ location._id }}">{{ location.name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="form.hasError(&quot;required&quot;, &quot;location&quot;)">Es muss ein Standort gewählt werden.</mat-error>
      </mat-form-field>
    </div>
    <div *ngIf="accounts">
      <mat-form-field>
        <mat-select formControlName="account" placeholder="Subkonto">
          <mat-option *ngFor="let account of accounts" value="{{ account._id }}">{{ account.name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="form.hasError(&quot;required&quot;, &quot;account&quot;)">Es muss ein Subkonto gewählt werden.</mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput="matInput" placeholder="Erste Ausführung" [matDatepicker]="startDatePicker" formControlName="startDate"/>
        <mat-datepicker-toggle matSuffix="matSuffix" [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker=""></mat-datepicker>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput="matInput" placeholder="Letzte Ausführung" [matDatepicker]="endDatePicker" formControlName="endDate"/>
        <mat-datepicker-toggle matSuffix="matSuffix" [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker=""></mat-datepicker>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-select formControlName="rhythm" placeholder="Wiederholungsrate" (ngModelChange)="updateRhythm()">
          <mat-option value="0">Einmalig</mat-option>
          <mat-option value="1">Monatlich</mat-option>
        </mat-select>
        <mat-error *ngIf="form.hasError(&quot;required&quot;, &quot;rhythm&quot;)">Es muss ein Rythmus ausgewählt werden.</mat-error>
      </mat-form-field>
    </div>
    <div *ngIf="form.get(&quot;rhythm&quot;).value &gt; 0">
      <div>
        <h4>Ende</h4>
        <mat-radio-group formControlName="endTypeType">
          <div>
            <mat-radio-button value="0">Nie</mat-radio-button>
          </div>
          <div>
            <mat-radio-button value="1">Am</mat-radio-button>
            <mat-form-field>
              <input matInput="matInput" placeholder="Enddatum" [matDatepicker]="endTypeDatePicker" formControlName="endTypeDate" [min]="minDate" (ngModelChange)="updateEndDate()"/>
              <mat-datepicker-toggle matSuffix="matSuffix" [for]="endTypeDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #endTypeDatePicker="" (monthSelected)="monthSelected($event)" startView="multi-year" [disabled]="endTypeDatepickerDisabled"></mat-datepicker>
            </mat-form-field>
          </div>
          <div>
            <mat-radio-button value="2">Nach</mat-radio-button>
            <mat-form-field>
              <mat-select formControlName="endTypeRepetitions" placeholder="Wiederholungen" (ngModelChange)="updateEndDate()">
                <mat-option *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]" value="{{ i }}">{{ i }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-radio-group>
      </div>
    </div>
  </div>
  <div mat-dialog-actions="mat-dialog-actions" align="end"><a mat-button="mat-button" (click)="dialogRef.close( false )">Abbrechen</a>
    <button mat-raised-button="mat-raised-button" type="submit" color="primary">Speichern</button>
  </div>
</form>