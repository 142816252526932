import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/auth/auth.service';
import { Router } from '@angular/router';
import { StorageService } from './../core/storage/storage.service';
import { UserService } from 'src/app/shared/services/user/user.service';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss']
})
export class ImpressumComponent implements OnInit {
  public impressumData = [
    {
      "name": "Gesellschaft",
      "img": "Mindset Solution GmbH <br> Carl-Schwemmer-Str. 9 <br> 90427 Nürnberg, Deutschland <br> Geschäftsführer: Swen Prokop, Michael Bergmann",
      
    }
  ];

  constructor() { }

  async ngOnInit() {
  }
}
