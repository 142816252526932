import { NgModule } from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { DatenschutzComponent } from './datenschutz.component';

@NgModule({
  declarations: [ DatenschutzComponent ],
  imports: [ 
    CommonModule,
    NgxUiLoaderModule,
    SharedModule,
    MatPaginatorModule
  ]
})
export class DatenschutzModule { }
