import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmComponent } from './confirm/confirm.component';
import { MaterialModule } from '../modules/material/material.module';
import { TocDialogComponent } from './toc-dialog/toc-dialog.component';
import { AccountDepositComponent } from './account-deposit/account-deposit.component';
import { AccountPayoutComponent } from './account-payout/account-payout.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StandingOrderComponent } from './standing-order/standing-order.component';
import { CreateComponent as StandingOrderCreateComponent } from './standing-order/create/create.component';
import { UpdateComponent as StandingOrderUpdateComponent } from './standing-order/update/update.component';
import { AlertComponent } from './alert/alert.component';
import { MenuCardComponent } from './menu-card/menu-card.component';
import { SupplierDeliveryDialogComponent } from './supplier-delivery-dialog/supplier-delivery-dialog.component';
import { AccountCardComponent } from './account-card/account-card.component';
import { RouterModule } from '@angular/router';
import { PasswordChangeComponent } from './password-change/password-change.component';
import {PasswordUserChangeComponent} from './password-change-user/password-change-user.component';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';


@NgModule( {
    declarations: [
        ConfirmComponent,
        AlertComponent,
        TocDialogComponent,
        AccountDepositComponent,
        AccountPayoutComponent,
        StandingOrderComponent,
        StandingOrderCreateComponent,
        StandingOrderUpdateComponent,
        MenuCardComponent,
        AccountCardComponent,
        PasswordChangeComponent,
        PasswordUserChangeComponent,
        SupplierDeliveryDialogComponent,
        TermsAndConditionsComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        CurrencyMaskModule,
        ReactiveFormsModule,
        RouterModule,
    ],
    exports: [
        StandingOrderComponent,
        MenuCardComponent,
        AccountCardComponent,
        SupplierDeliveryDialogComponent
    ]
} )
export class ComponentsModule {
}
