import { Injectable } from '@angular/core';
import { ApiService } from "../../../core/api/api.service";

@Injectable({
  providedIn: 'root'
})
export class SpecialFactorService {

  constructor(protected api: ApiService) { }

  async list() {
    return await this.api.rpc('crm/factor/list', {});
  }

  async create( specialFactor ) {
    return await this.api.rpc('crm/factor/create', specialFactor);
  }

  async get( id: string ) {
    return await this.api.rpc('crm/factor/get', {_id: id});
  }

  async update( specialFactor ) {
    return await this.api.rpc('crm/factor/edit', specialFactor);
  }

  async delete( id: string ) {
    return await this.api.rpc('crm/factor/remove', {_id: id});
  }
  async orderCheck( id: string ) {
    return await this.api.rpc('crm/factor/check', {_id: id});
  }
}
