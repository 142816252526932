import { CartService } from './cart/cart.service';
import { ProductService } from './product/product.service';
import { ShopCategoryService } from './shop-category/shop-category.service';
import { NotificationService } from './notification/notification.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from './user/user.service';
import { LocationService } from './location/location.service';
import { AccountService } from './account/account.service';
import { TransactionService } from './transaction/transaction.service';
import { StandingOrderService } from './standing-order/standing-order.service';
import { AclService } from './acl/acl.service';
import { SpecialFactorService } from './special-factor/special-factor.service';
import { TagService } from './tag/tag.service';
import { ShopSupplierService } from './shop-supplier/shop-supplier.service';
import { ShopSupplierCategoryService } from './shop-supplier-category/shop-supplier-category.service';
import { NavigationService } from './navigation/navigation.service';

@NgModule( {
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
    AccountService,
    AclService,
    CartService,
    LocationService,
    NotificationService,
    ProductService,
    ShopSupplierService,
    ShopCategoryService,
    ShopSupplierCategoryService,
    SpecialFactorService,
    StandingOrderService,
    TagService,
    TransactionService,
    UserService,
    NavigationService
  ],
} )
export class ServicesModule {
}
