import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ServicesModule } from './services/services.module';
import { ModulesModule } from './modules/modules.module';
import { ComponentsModule } from './components/components.module';
import { MaterialFileInputModule } from 'ngx-material-file-input';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ServicesModule,
    ModulesModule,
    ComponentsModule,
    MaterialFileInputModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    ServicesModule,
    ModulesModule,
    ComponentsModule
  ]
})
export class SharedModule { }
