import { NotificationService } from './../../services/notification/notification.service';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../../shared/services/user/user.service';

export class PasswordRepeatMatcher implements ErrorStateMatcher {
  isErrorState( control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null ): boolean {
    const isSubmitted = form && form.submitted;
    return !!( control && form.invalid && form.errors && form.errors.notSame && ( control.dirty || control.touched || isSubmitted ) );
  }
}

@Component( {
  selector: 'app-password-user-change',
  templateUrl: './password-change-user.component.html',
  styleUrls: [ './password-change-user.component.scss' ],
} )
export class PasswordUserChangeComponent implements OnInit {
  public form: UntypedFormGroup;
  public loading: boolean = false;
  public apiError: boolean = false;
  public submitted: boolean = false;
  public passwordRepeatMatcher: ErrorStateMatcher = new PasswordRepeatMatcher();
  public userId: string;
  public passwordCheck: {
    pwLength: boolean,
    capitalLetters: boolean,
    lowerLetters: boolean,
    numbers: boolean,
    specialChars: boolean
  } = {
    pwLength: false,
    capitalLetters: false,
    lowerLetters: false,
    numbers: false,
    specialChars: false,
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private notification: NotificationService,
    public dialogRef: MatDialogRef<PasswordUserChangeComponent>,
    @Inject( MAT_DIALOG_DATA ) public data: { user: string, disableClose?: boolean },
  ) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group( {
      password: [ '', [
        Validators.required,
        Validators.pattern( '^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\\d\\W])|(?=.*\\W)(?=.*\\d))|(?=.*\\W)(?=.*[A-Z])(?=.*\\d)).{8,}$' ),
      ] ],
      password_repeat: [ '', [ Validators.required ] ],
    }, {
      validators: [ this.matchValidator ],
    } );
  }

  protected matchValidator( form: UntypedFormGroup ) {
    let pass = form.controls.password.value,
      confirmPass = form.controls.password_repeat.value;

    return pass === confirmPass ? null : { notSame: true };
  }

  async submit() {
    this.apiError = false;
    this.submitted = true;

    if ( this.form.invalid ) {
      return;
    }

    this.form.controls.password.disable();
    this.form.controls.password_repeat.disable();

    try {
      await this.userService.changeUserPassword( this.data.user, this.form.controls.password.value );

      this.notification.showSnackbar( 'Das Passwort wurde erfolgreich aktualisiert.', 'OK' );
      this.dialogRef.close( true );
    } catch ( e ) {
      this.apiError = true;
    }


    this.form.controls.password.enable();
    this.form.controls.password_repeat.enable();
    this.loading = false;
  }

  checkPasswordRequirements() {
    let password = this.form.controls.password.value;

    this.passwordCheck = {
      pwLength: password.length >= 8,
      capitalLetters: /[A-Z]/.test( password ),
      lowerLetters: /[a-z]/.test( password ),
      numbers: /[0-9]/.test( password ),
      specialChars: !/^[a-zA-Z0-9]*$/.test( password ),
    };
  }
}
