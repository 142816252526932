import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Account, LOCATION } from '../../types';
import { ComponentType } from '@angular/cdk/overlay';
import { AccountDepositComponent } from '../account-deposit/account-deposit.component';
import { AccountPayoutComponent } from '../account-payout/account-payout.component';
import { MatDialog } from '@angular/material/dialog';
import { UserGroup } from '../../../authentication/authentication.reducer';
import { StorageService } from '../../../core/storage/storage.service';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';

@Component( {
  selector: 'app-account-card',
  templateUrl: './account-card.component.html',
  styleUrls: [ './account-card.component.scss' ],
} )
export class AccountCardComponent implements OnInit {
  @Input() location: Partial<LOCATION>;
  @Input() account: { total: number, name: string, _id: string };
  @Input() special: Boolean = false;
  @Output() onAccountUpdate = new EventEmitter<boolean>();
  public user$: Observable<UserGroup | string>;
  public userGroup = UserGroup;
  public role;

  constructor( 
    protected dialog: MatDialog, 
    protected storage: StorageService, 
    private store: Store<fromRoot.State>
    ) {
      this.user$ = this.store.select(fromRoot.getUserGroup);
  }

  ngOnInit() {
  }

  getDetailsLink() {
    let linkParts = [ '/locations', 'detail', this.location._id, 'accounts', 'detail' ];

    if ( this.account._id ) {
      linkParts.push( this.account._id );
    }

    return linkParts;
  }

  async makeTransaction( input: boolean ) {
    const dialogComponent: ComponentType<AccountDepositComponent | AccountPayoutComponent> = input ? AccountDepositComponent : AccountPayoutComponent;
    const dialogRef = await this.dialog.open( dialogComponent, {
      width: '400px',
      data: {
        location: this.location._id,
        subAccount: this.account._id,
      },
    } );

    dialogRef.afterClosed().subscribe( async result => {
      this.onAccountUpdate.emit( result );
    } );
  }
}
