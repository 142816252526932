import { Component, OnInit } from '@angular/core';
import { AclService } from "../../shared/services/acl/acl.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'app-acl',
  templateUrl: './acl.component.html',
  styleUrls: ['./acl.component.scss']
})
export class AclComponent implements OnInit {

  public denyForm: UntypedFormGroup;
  public allowForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private aclService: AclService
  ) { }

  ngOnInit() {
    this.denyForm = this.formBuilder.group({
      group: ['', Validators.required],
      path: ['', Validators.required],
    });
    this.allowForm = this.formBuilder.group({
      group: ['', Validators.required],
      path: ['', Validators.required],
    });
  }

  async submitAllow() {
    if (this.allowForm.invalid) {
      return;
    }

    try {
      await this.aclService.setAllow(this.allowForm.value.group, this.allowForm.value.path);
    } catch ( e ) {
      console.error(e);
    }
  }


  async submitDeny() {
    if (this.denyForm.invalid) {
      return;
    }

    try {
      await this.aclService.setDeny(this.denyForm.value.group, this.denyForm.value.path);
    } catch ( e ) {
      console.error(e);
    }
  }

}
