import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/api/api.service';
import { SupplierCategoryItem } from '../../types';

@Injectable({
  providedIn: 'root'
})
export class ShopSupplierCategoryService {

  constructor(
    protected api: ApiService,
  ) { }

  protected async _call( action: string, data: any = {} ) {
    return await this.api.rpc( `shop/supplier-category/${ action }`, data );
  }

  async create( supplierCategoryItem: SupplierCategoryItem ) {
    return await this._call( 'create', supplierCategoryItem );
  }

  async update( supplierCategoryItem: SupplierCategoryItem) {
    return await this._call( 'edit', supplierCategoryItem );
  }

  async get( supplier_id: string, filter:string, pageSize: number, pageIndex:number ) {
    return await this._call( 'get', { supplier_id, filter, pageSize, indexPage: pageIndex } );
  }
  
  async uploadCategoryCSV( supplier_id: string, data: any ) {
    return await this._call( 'uploadCategoryCSV', { supplier_id, data } );
  }
}
