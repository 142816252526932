import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { TOKEN_STORAGE, GROUPS_STORAGE } from '../../constants';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public authenticated: boolean = false;
  public token: string;
  userIdAndGroupSubject = new Subject<any>();
  userIdAndGroupObserver = this.userIdAndGroupSubject.asObservable();

  constructor(
    private storage: StorageService,
    private router: Router,
  ) { }

  async isAuthenticated(): Promise<boolean> {
    return this.storage
      .get( TOKEN_STORAGE )
      .then( async token => {
        this.authenticated = new Date().getTime() < await this.storage.get('expireDate');
        this.token = token;
        if(!this.authenticated) {
          this.logout();
          this.router.navigate(['/login']);
          this.userIdAndGroupSubject.next('')
        }
        return this.authenticated;
      }, () => false );
  }

  login( token: string, ) {
    this.token = token;
    this.storage.set( TOKEN_STORAGE, token );
    const date = new Date();
    this.storage.set('expireDate', date.getTime() + 30*60000)
    return this.authenticated = true;
  }
  
  async refreshUserIdentifierAndGroup(user_identifier: string, user_group: string) {
    let user = { user_identifier: user_identifier, user_group: user_group };

    this.userIdAndGroupSubject.next(user);
  }

  logout() {
    // this.events.publish( 'auth:logout' );
    this.storage.remove( TOKEN_STORAGE );
    this.storage.remove( 'gen_password' );
    this.storage.remove( 'toc_accepted' );
    this.storage.remove( 'cart_id' );
    this.storage.remove( 'kiku' );
    this.storage.remove( 'user_identifier' );
    this.storage.remove( 'user_group' );
    this.storage.remove( 'expireDate' );
    this.storage.remove( 'emailSendInfoOrder' );

    this.storage.removeSession( TOKEN_STORAGE );
    this.storage.removeSession( 'gen_password' );
    this.storage.removeSession( 'toc_accepted' );
    this.storage.removeSession( 'cart_id' );
    this.storage.removeSession( 'kiku' );
    this.storage.removeSession( 'user_identifier' );
    this.storage.removeSession( 'user_group' );
    this.token = null;
    return this.authenticated = false;
  }
}
