
<div class="container__row">
  <div class="col-4 col-sm-4 col-md-4 col-lg-4">
    <app-menu-card icon="user_settings" title="Einstellungen Benutzer" [link]="[&quot;users&quot;]"> </app-menu-card>
  </div>
  <div class="col-4 col-sm-4 col-md-4 col-lg-4">
    <app-menu-card icon="calculator_settings" title="Einstellungen Budget" [link]="[&quot;accounts&quot;]"> </app-menu-card>
  </div>
  <div class="col-4 col-sm-4 col-md-4 col-lg-4">
    <app-menu-card icon="shop_settings" title="Einstellungen Einkauf" [link]="[&quot;shop&quot;]"> </app-menu-card>
  </div>
</div>