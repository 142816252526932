import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { AuthenticationComponent } from './authentication.component';
import { ResetComponent } from "./reset/reset.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";

const routes: Routes = [ {
  path: '',
  component: AuthenticationComponent,
  children: [
    {
      path: 'login',
      component: LoginComponent
    },
    {
      path: 'reset',
      component: ResetComponent
    },
    {
      path: 'reset-confirm/:id',
      component: ResetPasswordComponent
    }
  ]
}];

@NgModule( {
  imports: [ RouterModule.forChild( routes ) ],
  exports: [ RouterModule ]
} )
export class AuthenticationRoutingModule { }
