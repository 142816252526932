import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationModule } from './authentication/authentication.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardModule } from './dashboard/dashboard.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AuthService } from './core/auth/auth.service';
import { Router } from '@angular/router';
import { SettingsModule } from './settings/settings.module';
import localeDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
import { FooterComponent } from './structure/footer/footer.component';
import { StoreModule } from '@ngrx/store';
import { reducers } from './app.reducer';
import { NgxUiLoaderModule, NgxUiLoaderConfig } from 'ngx-ui-loader';
import { CurrencyMaskConfig, CurrencyMaskModule, CURRENCY_MASK_CONFIG } from "ng2-currency-mask";
import { ImpressumModule } from './impressum/impressum.module';
import { DatenschutzModule } from './datenschutz/datenschutz.module';
import { AGBModule } from './agb/agb.module';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS,MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DATE_FORMATS } from './ordersEinkauf/orders-einkauf-list/dateFormat';
import { SpecialOrderModule } from './specialOrder/special-order.module';

// import { HttpModule } from '@angular/http';

registerLocaleData(localeDe, 'de');
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#f4a716',
  bgsOpacity: 0.5,
  bgsPosition: 'center-center',
  bgsSize: 60,
  bgsType: 'ball-spin-clockwise',
  blur: 5,
  delay: 0,
  fgsColor: '#f4a716',
  fgsPosition: 'center-center',
  fgsSize: 60,
  fgsType: 'ball-spin-clockwise',
  gap: 24,
  logoPosition: 'center-center',
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(40, 40, 40, 0.8)',
  pbColor: '#f4a716',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: true,
  minTime: 0
};

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "left",
  allowNegative: false,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: "."
};

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthenticationModule,
    DashboardModule,
    SettingsModule,
    SpecialOrderModule,
    ImpressumModule,
    AGBModule,
    DatenschutzModule,
    CoreModule,
    SharedModule,
    CurrencyMaskModule,
    StoreModule.forRoot({}),
    StoreModule.forFeature('categories', reducers.categories),
    StoreModule.forFeature('auth', reducers.auth),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },

  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private auth: AuthService,
    private router: Router,
  ) {
  }

  async logout() {
    this.auth.logout();

    this.router.navigate(['/login']);
  } 
}
