import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../core/auth/auth.service';
import { ApiService } from '../../core/api/api.service';
import { AzureAuthError, User } from '../../shared/types';
import { Observable } from 'rxjs';
import { UserService } from '../../shared/services/user/user.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import { UserGroup } from '../authentication.reducer';
import { SetUserGroup } from '../authentication.action';
import { AppComponent } from '../../app.component';
import { StorageService } from '../../core/storage/storage.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  public mail: string;
  public user: User = null;
  public form: UntypedFormGroup;
  public userIdentifier;
  public userGroupshow;
  public apiError: boolean = false;
  public submitted: boolean = false;
  public isLoggingIn: boolean = false;
  public user$: Observable<UserGroup | string>;
  public azureError: AzureAuthError;

  constructor(
    private auth: AuthService,
    private api: ApiService,
    private userService: UserService,
    private appComponent: AppComponent,
    private storage: StorageService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private store: Store<fromRoot.State>,
  ) { }


  async ngOnInit() {
    this.route.queryParams.subscribe(async (params) => {
      if (!params.access_token) return;

      this.isLoggingIn = true;
      try {
        const token = await this.userService.azureLogin(params.access_token);
        await this.login(token);
      } catch(e) {
        this.azureError = e || 'Something went wrong loging with azure!';
        this.isLoggingIn = false;
        this.router.navigate([], {
          queryParams: {
            'access_token': undefined,
          },
        });
      }
    });

    this.form = this.formBuilder.group({
      mail: [''],
      password: ['']
    });

    if (await this.auth.isAuthenticated()) {
      this.redirectByGroups([]);
      // this.auth.login( await this.storage.get('kiku') )
    }
  }

  public checkIfEmail(inputValue) {
    if (inputValue && ! /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$/gm.test(inputValue)) {
      return true;
    } else if (!inputValue) {
      return true;
    } else {
      return false;
    }
  }

  async submit() {
    if (!this.form.value.mail || !this.form.value.password || this.checkIfEmail(this.form.value.mail)) {
      this.form = this.formBuilder.group({
        mail: ['', [Validators.required]],
        password: ['', [Validators.required]]
      });
      return this.apiError = true;
    }

    this.submitted = true;
    this.clearStorage();

    if (this.form.invalid) {
      return false;
    }

    this.form.controls.mail.disable();
    this.form.controls.password.disable();
    this.apiError = false;

    this.isLoggingIn = true;
    // login!
    try {
      const token = await this.userService.login((this.form.value.mail).toLowerCase(), this.form.value.password);
      await this.login(token);
    } catch (error) {
      this.apiError = true;
    }

    this.form.controls.mail.enable();
    this.form.controls.password.enable();
    this.submitted = false;
    this.isLoggingIn = false;
  }
  private async getUser() {
    let userId = await this.getUserId();
    if (!userId) return;
    if (!this.user || this.user?._id === userId) {
      this.user = await this.userService.getUser(userId);
    }

    return this.user;
  }

  private async getUserId() {
    const id = await this.getUserdataFromToken(this.auth.token);
    return id.id;
  }

  async getUserdataFromToken(token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(atob(base64));
  }

  async redirectByGroups(groups: string[]) {
    if (groups[0] === 'lieferant') {
      return this.router.navigate(['/supplier/list']);
    }
    this.router.navigate(['/dashboard']);
  }

  private clearStorage(): void {
    this.storage.remove('kiku');
    this.storage.remove('gen_password');
    this.storage.remove('toc_accepted');
    this.storage.remove('cart_id');
    this.storage.remove('user_identifier');
    this.storage.remove('user_group');
    this.storage.removeSession('kiku');
    this.storage.removeSession('gen_password');
    this.storage.removeSession('toc_accepted');
    this.storage.removeSession('cart_id');
    this.storage.removeSession('user_identifier');
    this.storage.removeSession('user_group');
  }

  private async login(token: string) {
    try {
      this.auth.login(token);
      this.api.authenticate(token);

      const user = await this.getUser();
      if (!user) return;
      this.userIdentifier = user.identifier;
      const userGroup = user.groups[0];
      this.userGroupshow = userGroup;

      //DP
      this.storage.set('user_identifier', this.userIdentifier);
      this.storage.set('user_group', this.userGroupshow);

      if (this.userGroupshow == "super_admin") {
        this.userGroupshow = "Super Admin";
      }
      else if (this.userGroupshow == "admin") {
        this.userGroupshow = "Admin";
      }
      else if (this.userGroupshow == "einkauf") {
        this.userGroupshow = "Einkauf";
      }
      else if (this.userGroupshow == "user") {
        this.userGroupshow = "User";
      }
      else if (this.userGroupshow == "lieferant") {
        this.userGroupshow = "Lieferant";
      }

      //Refresh Data in Footer
      this.auth.refreshUserIdentifierAndGroup(this.userIdentifier, this.userGroupshow);

      this.store.dispatch(new SetUserGroup(userGroup));
      this.appComponent.user$ = this.store.select(fromRoot.getUserGroup);
      if (userGroup === 'lieferant') {
        this.redirectByGroups(['lieferant']);
      } else {
        this.redirectByGroups([]);
      }
    } catch (e) {
      this.apiError = true;
      this.isLoggingIn = false;
      this.form.reset();
    }
  }
}

